<template>
  <popup-base
    title=" "
    size="md"
    @close="active = $event"
    :showModal="active"
    :buttonCloseHidden="true"
    class="loading-modal onpoint-loadingmodal"
  >
    <template slot="content">
      <div class="flex flex-col items-center pt-4">
        <div id="div-with-loading" class="vs-con-loading__container"></div>

        <h2 class="flex flex-col items-center text-center font-semibold mt-8">
          Aguarde
        </h2>
        <p class="text-center my-8 mx-2">
          {{ message || "Estamos processando a solicitação." }}
        </p>
      </div>
    </template>
  </popup-base>
</template>

<script>
export default {
  name: "loading-popup",
  data: () => ({
    active: true,
    message: ""
  }),
  mounted() {
    this.initPopup();
  },
  methods: {
    initPopup() {
      return this.$vs.loading({
        container: "#div-with-loading",
        scale: 2
      });
    }
  }
};
</script>
<style lang="scss">
#div-with-loading {
  width: 100px;
  height: 100px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-modal {
  ::v-deep .vs-popup--header {
    background: white;
  }

  .footer-popup-base {
    display: none;
  }

  h2 {
    i {
      font-size: 2em;
    }
  }

  h2,
  p {
    color: initial;
  }
}
</style>
