import { documentProvider } from "../../providers/document-provider";

export default {
  namespaced: true,
  state: {
    documentos: {}
  },
  mutations: {
    updateDocumentos(state, documentos) {
      state.documentos = documentos;
    }
  },
  actions: {
    async getDocumentsList({ commit }) {
      return await documentProvider.getDocuments().then(response => {
        commit("updateDocumentos", response.RequestDocuments);
      });
    }
  },
  getters: {
    documents(state) {
      return state.documentos;
    }
  }
};
