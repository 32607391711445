var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.input.Type !== "Repeater"
    ? _c(
        "div",
        { staticClass: "container-element lista-auto-inputs" },
        [
          _c("p", { staticClass: "label-content label" }, [
            _vm._v(_vm._s(_vm.input.Label)),
          ]),
          _c("ValidationProvider", {
            attrs: { rules: _vm.validatorRuleObject, name: _vm.input.Label },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function ({ errors }) {
                    return [
                      _vm.input.Type === "TextArea"
                        ? _c("jodit-editor", {
                            attrs: { config: _vm.joditConfig },
                            on: {
                              input: function ($event) {
                                return _vm.emitFunction("input", $event)
                              },
                              change: function ($event) {
                                return _vm.emitFunction("change", $event)
                              },
                            },
                            model: {
                              value: _vm.input.Value,
                              callback: function ($$v) {
                                _vm.$set(_vm.input, "Value", $$v)
                              },
                              expression: "input.Value",
                            },
                          })
                        : _vm.input.Type === "Date"
                        ? _c("datepicker", {
                            staticClass: "w-full",
                            attrs: {
                              language: _vm.ptBR,
                              format: "dd/MM/yyyy",
                              name: _vm.input.Name,
                              "data-label": _vm.input.Label,
                            },
                            on: {
                              input: function ($event) {
                                return _vm.emitFunction("input", $event)
                              },
                              change: function ($event) {
                                return _vm.emitFunction("change", $event)
                              },
                            },
                            model: {
                              value: _vm.input.Value,
                              callback: function ($$v) {
                                _vm.$set(_vm.input, "Value", $$v)
                              },
                              expression: "input.Value",
                            },
                          })
                        : _vm.input.Type === "Money"
                        ? _c("currency-input", {
                            staticClass:
                              "w-full vs-inputx vs-input--input large hasValue",
                            attrs: {
                              maxlength: 50,
                              name: _vm.input.Name,
                              "data-label": _vm.input.Label,
                            },
                            on: {
                              input: function ($event) {
                                return _vm.emitFunction("input", $event)
                              },
                              change: function ($event) {
                                return _vm.emitFunction("change", $event)
                              },
                            },
                            model: {
                              value: _vm.input.Value,
                              callback: function ($$v) {
                                _vm.$set(_vm.input, "Value", $$v)
                              },
                              expression: "input.Value",
                            },
                          })
                        : _vm.input.Type === "Number"
                        ? _c("vs-input", {
                            staticClass: "w-full",
                            attrs: {
                              maxlength: 50,
                              size: "large",
                              name: _vm.input.Name,
                              "data-label": _vm.input.Label,
                            },
                            on: {
                              input: function ($event) {
                                return _vm.emitFunction("input", $event)
                              },
                              change: function ($event) {
                                return _vm.emitFunction("change", $event)
                              },
                            },
                            model: {
                              value: _vm.input.Value,
                              callback: function ($$v) {
                                _vm.$set(_vm.input, "Value", _vm._n($$v))
                              },
                              expression: "input.Value",
                            },
                          })
                        : _vm.input.Type === "Select"
                        ? _c("v-select", {
                            staticClass: "w-full",
                            attrs: {
                              size: "large",
                              value: _vm.input.Value,
                              options: _vm.getChoices(_vm.input.Choices),
                              name: _vm.input.Name,
                              placeholder: "",
                              dir: _vm.$vs.rtl ? "rtl" : "ltr",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.emitFunction("input", $event)
                              },
                              change: function ($event) {
                                return _vm.emitFunction("change", $event)
                              },
                            },
                          })
                        : _c("vs-input", {
                            staticClass: "w-full",
                            attrs: {
                              size: "large",
                              name: _vm.input.Name,
                              disabled: _vm.disabledTagFieldsEndosso,
                              maxlength: 50,
                              "data-label": _vm.input.Label,
                            },
                            on: {
                              input: function ($event) {
                                return _vm.emitFunction("input", $event)
                              },
                              change: function ($event) {
                                return _vm.emitFunction("change", $event)
                              },
                            },
                            model: {
                              value: _vm.input.Value,
                              callback: function ($$v) {
                                _vm.$set(_vm.input, "Value", $$v)
                              },
                              expression: "input.Value",
                            },
                          }),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: errors[0],
                              expression: "errors[0]",
                            },
                          ],
                          staticClass: "text-danger text-sm",
                        },
                        [_vm._v("\n      " + _vm._s(errors[0]) + "\n    ")]
                      ),
                    ]
                  },
                },
              ],
              null,
              false,
              4011955742
            ),
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }