import Vue from "vue";
import StringMask from "string-mask";

Vue.filter("maskCpf", function (value) {
  if (value && value.length === 11) {
    const formatter = new StringMask("000.000.000-00");
    const result = formatter.apply(value);
    return result;
  }
  return value;
  
});

Vue.filter("maskCnpj", function (value) {
  if (value && value.length === 14) {
    const formatter = new StringMask("00.000.000/0000-00");
    const result = formatter.apply(value);
    return result;
  }
  return value;
});

Vue.filter("maskCpfCnpj", function (value) {
  if (value && value.length === 14) {
    const formatter = new StringMask("00.000.000/0000-00");
    const result = formatter.apply(value);
    return result;
  } else if (value && value.length === 11) {
    const formatter = new StringMask("000.000.000-00");
    const result = formatter.apply(value);
    return result;
  }
  
  return value;
});

Vue.filter("maskCurrency", function (value) {
  if (typeof value !== "number") {
    return value;
  }
  const formatter = new Intl.NumberFormat("pt-br", {
    style: "currency",
    currency: "BRL",
    minimumFractionDigits: 2
  });

  return formatter.format(value);
});