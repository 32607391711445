import axios from "axios";
import Vue from "vue";
import store from "../store/index";

Vue.directive("storage", {
  bind(el, binding, vnode) {
    el.addEventListener("click", (e) => {
      e.preventDefault();
      const storageInfo = store.getters["auth/storageInfo"];
      const blobName = el.getAttribute("href");
      const originalFileName = el.getAttribute("download");

      if (!storageInfo) throw new Error("storageInfo not found!");

      const { Uri, ContainerName, Signature } = storageInfo;
      const url = `${Uri}/${ContainerName}/${blobName}${Signature}`;

      return axios
        .get(url)
        .then(response => {
          const blob = new Blob([response.data]);
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = originalFileName;
          link.click();
        });
    });
  }
});