import Vue from "vue";

Vue.filter("labelBanco", function (value, bancos) {
  if (value && typeof value == "number" && bancos.length) {
    const banco = bancos.find(banco => banco.Id === value);
    return banco.Name;
  } else if(value && bancos.length) {
    const banco = bancos.find(banco => banco.Id === value.Id);
    return banco.Name;
  }
});
