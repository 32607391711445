import instance from "../../axios";

export default {
  namespaced: true,
  state: {
    primeiroAcesso: {}
  },
  mutations: {
    setPrimeiroAcesso (state, data) {
      state.primeiroAcesso = data;
    }
  },
  actions: {
    validarTokenDeAcesso (_, token) {
      return instance({
        method: "post",
        url: "/api/Security/ValidateUserInfoChangePasswordRequest",
        data: {
          UniqueId:token
        }
      })
        .then(response => {
          return response.data;
        });
    },

    atualizarSenha ({ commit }, payload) {
      return instance({
        method: "post",
        url: "/api/Security/ChangeUserInfoPassword",
        data: payload
      })
        .then(data => commit("setPrimeiroAcesso", data) || data);
    }
  },
  getters: {
    primeiroAcesso (state) {
      return state.primeiroAcesso;
    }
  }
};
