var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "popup-base",
    {
      staticClass: "success-modal onpoint-successmodal",
      attrs: {
        showModal: _vm.active,
        size: "sm",
        title: " ",
        "button-close-hidden": false,
      },
      on: {
        close: function ($event) {
          _vm.active = false
        },
      },
    },
    [
      _c("template", { slot: "content" }, [
        _c(
          "div",
          { staticClass: "flex flex-col items-center pt-4" },
          [
            _c(
              "h2",
              { staticClass: "flex flex-col items-center text-center w-1/2" },
              [
                _c("vs-icon", {
                  staticClass: "mb-6",
                  attrs: { color: "success", icon: "check_circle" },
                }),
                _vm._v("\n        Sucesso\n      "),
              ],
              1
            ),
            !_vm.message || typeof _vm.message == "string"
              ? _c("p", { staticClass: "text-center my-8 mx-2" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.message || "Operação realizada com sucesso!") +
                      "\n      "
                  ),
                ])
              : _vm._e(),
            Array.isArray(_vm.message)
              ? _c(
                  "div",
                  _vm._l(_vm.message, function (msg) {
                    return _c(
                      "p",
                      { key: msg, staticClass: "text-center my-8 mx-2" },
                      [_vm._v("\n          " + _vm._s(msg) + "\n        ")]
                    )
                  }),
                  0
                )
              : _vm._e(),
            _vm._t("default"),
          ],
          2
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }