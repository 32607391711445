import router from "../router";
import store from "../store/index";

export function logout() {
  store.dispatch("auth/logout");

  router.push({
    name: "page-login"
  });

  /**
  if(applicationConfig.dns && applicationConfig.dns.toLowerCase() !== "localhost"){
    router.push({
      name: 'page-login',
      params: { companyDns: applicationConfig.dns}
    })
  }
  else{
    router.push({
      name: 'page-login'
    })
  }**/
}