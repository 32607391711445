import store from "../store/index";

class PerfilHelpers {
    checkPermission(funcionalities = []) {
        const menus = (store.getters["auth/userInfo"] || {}).Menus || [];

        if (menus && menus.length) {
            let menuFuncionalities = [];

            menus.forEach(menu => {
                menuFuncionalities = menuFuncionalities.concat(menu.MenuFuncionalities);
            });

            return !!menuFuncionalities.filter(funcionality =>
                funcionalities.includes(funcionality.Id)
            ).length;
        }
        return false;
    }

    checkPermissionKanban(pipeUniqueId) {
        const menus = (store.getters["auth/userInfo"] || {}).Menus || [];

        if (menus && menus.length) {
            let menuFuncionalities = (
                (menus.filter(menu => menu.Id == 1) || [])[0] || {}
            ).MenuFuncionalities;

            return !!menuFuncionalities.filter(
                funcionality => (funcionality.RefUniqueId == pipeUniqueId)
            ).length;
        }

        return false;
    }

    isBroker() {
        return (
            (store.getters["auth/userInfo"] || {}).UserTypeId ==
            this.userTypeEnum.BROKER ||
            (store.getters["auth/userInfo"] || {}).UserTypeId ==
            this.userTypeEnum.AGENT
        );
    }

    isAdvisor() {
        return (
            (store.getters["auth/userInfo"] || {}).ProfileName == "RiskAdviser"
        );
    }

    isInsurance() {
        return (
            (store.getters["auth/userInfo"] || {}).UserTypeId ==
            this.userTypeEnum.INSURANCE ||
            (store.getters["auth/userInfo"] || {}).UserTypeId ==
            this.userTypeEnum.ADVISOR
        );
    }

    menusUser() {
        if (!(store.getters["auth/userInfo"] || {}).UserId) return [];

        if (!store.getters["menuItens"]) {
            const menus = [];
            let menusUser = (store.getters["auth/userInfo"] || {}).Menus || [];

            menusUser.forEach(menu => {
                switch (menu.Id) {
                    case 1:
                        if (
                            this.checkPermission([
                                this.menuFuncionalitiesEnum.VISUALIZAR_ESTEIRAS,
                                this.menuFuncionalitiesEnum
                                .GERENCIAR_PIPE_CADASTRO,
                                this.menuFuncionalitiesEnum
                                .GERENCIAR_PIPE_CREDITO,
                                this.menuFuncionalitiesEnum
                                .GERENCIAR_PIPE_NOMEACAO,
                                this.menuFuncionalitiesEnum.GERENCIAR_PIPE_PEP,
                                this.menuFuncionalitiesEnum
                                .GERENCIAR_PIPE_RESSEGURO,
                                this.menuFuncionalitiesEnum
                                .GERENCIAR_PIPE_SUBSCRICAO
                            ])
                        )
                            menus.push({
                                url: "/kanban",
                                routerName: "kanban",
                                name: "Esteiras Seguradora",
                                slug: "kanban",
                                icon: "onpoint-list-checks",
                                id: menu.Id,
                                order: menu.Order
                            });

                        break;
                    case 2:
                        if (
                            this.checkPermission([
                                this.menuFuncionalitiesEnum.GERENCIAR_TOMADORES,
                                this.menuFuncionalitiesEnum.VISUALIZAR_TOMADORES,
                                this.menuFuncionalitiesEnum.GERENCIAR_TOMADORES_SEGURADORA,
                                this.menuFuncionalitiesEnum.VISUALIZAR_TOMADORES_SEGURADORA
                            ])
                        )
                            menus.push({
                                url: "/tomadores",
                                routerName: "tomadores",
                                name: "Tomadores",
                                slug: "tomador",
                                icon: "onpoint-user",
                                id: menu.Id,
                                order: menu.Order
                            });
                        break;
                    case 3:
                        if (
                            this.checkPermission([
                                this.menuFuncionalitiesEnum.VISUALIZAR_USUARIOS,
                                this.menuFuncionalitiesEnum.GERENCIAR_USUARIOS,
                                this.menuFuncionalitiesEnum.GERENCIAR_PERFIS
                            ])
                        )
                            menus.push({
                                url: "/usuarios",
                                routerName: "usuarios",
                                name: "Usuários",
                                slug: "usuarios",
                                icon: "onpoint-users-three",
                                id: menu.Id,
                                order: menu.Order
                            });

                        break;
                    case 4:
                        if (
                            this.checkPermission([
                                this.menuFuncionalitiesEnum.GERENCIAR_SEGURADOS,
                                this.menuFuncionalitiesEnum.VISUALIZAR_SEGURADO
                            ])
                        )
                            menus.push({
                                url: "/segurados",
                                routerName: "segurados",
                                name: "Segurados",
                                slug: "segurado",
                                icon: "onpoint-user",
                                id: menu.Id,
                                order: menu.Order
                            });

                        break;
                    case 5:
                        if (
                            this.checkPermission([
                                this.menuFuncionalitiesEnum.PARAMETRIZAR_SEGURADORA
                            ])
                        )
                            menus.push({
                                url: "/parametrizacao",
                                routerName: "parametrizacao",
                                name: "Parametrização",
                                slug: "parametrizacao",
                                icon: "onpoint-gear ",
                                id: menu.Id,
                                order: menu.Order
                            });

                        break;
                    case 6:
                        if (
                            this.checkPermission([
                                this.menuFuncionalitiesEnum.VISUALIZAR_APOLICES
                            ])
                        )
                            menus.push({
                                url: "/apolices",
                                routerName: "apolices",
                                name: "Apólices",
                                slug: "apolices",
                                icon: "onpoint-note",
                                id: menu.Id,
                                order: menu.Order
                            });

                        break;
                    case 7:
                        if (
                            this.checkPermission([
                                this.menuFuncionalitiesEnum.GERENCIAR_PROPOSTAS_SEGURADORA,
                                this.menuFuncionalitiesEnum.VISUALIZAR_PROPOSTAS_SEGURADORA
                            ])
                        )
                            menus.push({
                                url: "/propostas-seguradora",
                                routerName: "propostas-seguradora",
                                name: "Propostas",
                                slug: "propostas-seguradora",
                                icon: "onpoint-currency-dollar",
                                id: menu.Id,
                                order: menu.Order
                            });
                        else if (
                            this.checkPermission([
                                this.menuFuncionalitiesEnum.GERENCIAR_PROPOSTAS,
                                this.menuFuncionalitiesEnum.VISUALIZAR_PROPOSTAS
                            ])
                        )
                            menus.push({
                                url: "/propostas",
                                routerName: "propostas",
                                name: "Propostas",
                                slug: "propostas",
                                icon: "onpoint-currency-dollar",
                                id: menu.Id,
                                order: menu.Order
                            });

                        break;
                    case 8:
                        if (
                            this.checkPermission([this.menuFuncionalitiesEnum.VISUALIZAR_BI])
                        )
                            menus.push({
                                url: "/bi/embbeded",
                                routerName: "bi_embbeded",
                                name: "BI",
                                slug: "bi",
                                icon: "onpoint-chart-line-up",
                                id: menu.Id,
                                order: menu.Order
                            });

                        break;
                    case 9:
                        if (
                            this.checkPermission([
                                this.menuFuncionalitiesEnum.GERENCIAR_DOCUMENTOS,
                                this.menuFuncionalitiesEnum.VISUALIZAR_DOCUMENTOS
                            ])
                        )
                            menus.push({
                                url: "/documentos",
                                routerName: "documentos",
                                name: "Documentos",
                                slug: "documentos",
                                icon: "onpoint-file",
                                id: menu.Id,
                                order: menu.Order
                            });

                        break;
                    case 13:
                        if (
                            this.checkPermission([
                                this.menuFuncionalitiesEnum.GERENCIAR_INTEGRACOES,
                                this.menuFuncionalitiesEnum.GERENCIAR_INTEGRACOES
                            ])
                        )
                            menus.push({
                                url: "/integracoes",
                                routerName: "integracoes",
                                name: "Consulta integrações",
                                slug: "Integrações",
                                icon: "onpoint-share-network",
                                id: menu.Id,
                                order: menu.Order
                            });

                        break;
                    case 10:
                        if (
                            this.checkPermission([
                                this.menuFuncionalitiesEnum.VISUALIZAR_CORRETORES,
                                this.menuFuncionalitiesEnum.GERENCIAR_CORRETORES
                            ])
                        )
                            menus.push({
                                url: "/corretores",
                                routerName: "corretores",
                                name: "Corretores",
                                slug: "corretores",
                                icon: "onpoint-suitcase",
                                id: menu.Id,
                                order: menu.Order
                            });
                        else if (
                            this.checkPermission([
                                this.menuFuncionalitiesEnum.GERENCIAR_CORRETORA,
                                this.menuFuncionalitiesEnum.VISUALIZAR_CORRETORA
                            ])
                        ) {
                            menus.push({
                                url: "/corretor",
                                routerName: "corretor",
                                name: "Dados da Corretora",
                                slug: "corretor",
                                icon: "onpoint-user",
                                id: menu.Id,
                                order: menu.Order
                            });
                        } else if (
                            this.checkPermission([
                                this.menuFuncionalitiesEnum.LISTAR_CORRETORES_ASSESSOR
                            ])
                        ) {
                            menus.push({
                                url: "/corretor-assessoria",
                                routerName: "corretor-assessoria",
                                name: "Lista de corretores",
                                slug: "corretor-assessoria",
                                icon: "onpoint-list-checks",
                                id: menu.Id,
                                order: menu.Order
                            });
                        }

                        break;
                    case 14:
                        if (
                            this.checkPermission([
                                this.menuFuncionalitiesEnum.GERENCIAR_CONSULTAS_BUREAU
                            ])
                        )
                        menus.push({
                            url: "/gerenciamento-consultas-bureau",
                            routerName: "gerenciamento-consultas-bureau",
                            name: "Consultas bureau",
                            slug: "gerenciamento-consultas-bureau",
                            icon: "onpoint-warning-circle",
                            id: menu.Id,
                            order: menu.Order
                        });

                        break;
                }
            });

            store.commit(
                "setMenuItens",
                (menus || []).sort((a, b) => (a.order > b.order ? 1 : -1))
            );
        }

        return store.getters["menuItens"];
    }

    menuHomeRouterName() {
        let user = store.getters["auth/userInfo"] || {};
        if (!user.UserId) return "page-login";

        let menus = this.menusUser() || [];
        let homeMenuId = user.HomeMenuId;

        if (
            homeMenuId > 0 &&
            menus.filter(x => {
                return x.id == homeMenuId;
            }).length
        ) {
            store.commit(
                "setMenuHome",
                menus.find(x => x.id == homeMenuId).routerName
            );
            return menus.find(x => x.id == homeMenuId).routerName;
        } else {
            store.commit("setMenuHome", menus[0].routerName);
            return menus[0].routerName;
        }
    }

    userTypeEnum = Object.freeze({
        INSURANCE: 1,
        BROKER: 2,
        POLICY_HOLDER: 3,
        OTHER: 4,
        AGENT: 5,
        ADVISOR: 6
    });

    menuFuncionalitiesEnum = Object.freeze({
        // Kanban
        VISUALIZAR_ESTEIRAS: 1, //Visualizar esteiras
        GERENCIAR_PIPE_CADASTRO: 34, //Gerenciar pipe de cadastro
        GERENCIAR_PIPE_CREDITO: 35, //Gerenciar pipe de Crédito
        GERENCIAR_PIPE_NOMEACAO: 36, //Gerenciar pipe de Nomeação
        GERENCIAR_PIPE_PEP: 37, //Gerenciar pipe de PEP
        GERENCIAR_PIPE_RESSEGURO: 38, //Gerenciar pipe de Resseguro
        GERENCIAR_PIPE_SUBSCRICAO: 39, //Gerenciar pipe de Subscrição
        // BI
        VISUALIZAR_BI: 3, // Visualizar BI
        // Documentos
        VISUALIZAR_DOCUMENTOS: 4, //Visualizar documentos
        GERENCIAR_DOCUMENTOS: 5, //Gerenciar documentos
        // Propostas
        GERENCIAR_PROPOSTAS: 6, //Gerenciar proposta
        VISUALIZAR_PROPOSTAS: 7, //Visualizar propostas
        GERENCIAR_PROPOSTAS_SEGURADORA: 31, //Gerenciar propostas da seguradora
        VISUALIZAR_PROPOSTAS_SEGURADORA: 32, //Visualizar propostas da seguradora
        // Apólices
        DOWNLOAD_DO_LAUDO_DE_EMISSAO: 15, //Download do laudo de emissão
        CANCELAR_APOLICE_MANUALMENTE: 23,
        VISUALIZAR_APOLICES: 24, //Visualizar propostas
        SOLICITAR_ENDOSSO: 25,
        // Usuários
        VISUALIZAR_USUARIOS: 8, //Visualizar usuários
        GERENCIAR_USUARIOS: 9, //Gerenciar usuários
        GERENCIAR_PERFIS: 10, //Gerenciar perfis
        // Segurados
        VISUALIZAR_SEGURADO: 11, //Visualizar segurados
        GERENCIAR_SEGURADOS: 12, //Gerenciar segurados
        // Tomadores
        GERENCIAR_TOMADORES: 13, //Gerenciar tomadores
        VISUALIZAR_TOMADORES: 14, //Visualizar tomadores
        GERENCIAR_TOMADORES_SEGURADORA: 29, //Gerenciar tomadores
        VISUALIZAR_TOMADORES_SEGURADORA: 30, //Visualizar tomadores
        REPROCESSAMENTO: 16, //Reprocessar
        IGNORAR_ASSINATURA_DE_CCG: 17, //Ignorar assinatura do CCG
        BLOQUEAR_RESTRITIVOS_DO_TOMADOR: 19, //Bloquear restritivos do tomador
        RESETAR_GRUPO_ECONOMICO: 20, // Resetar grupo econômico do tomador
        APROVACAO_MANUAL: 27, //Aprovação manual do tomador
        VISUALIZAR_TELA_DE_DOCUMENTOS:43,//Visualiza a tela de documentos dentro de um tomador
        VISUALIZAR_ANALISE_DE_CREDITO:44,// visualiza a aba analise de credito dentro do Cadastro Completo
        GERENCIAR_CCG: 45,
        // Corretores
        VISUALIZAR_CORRETORES: 22, //Visualizar corretores
        GERENCIAR_CORRETORES: 21, //Gerenciar corretores
        GERENCIAR_CORRETORA: 2, //Gerenciar dados da corretora
        VISUALIZAR_CORRETORA: 28, //Visualizar dados da corretora
        LISTAR_CORRETORES_ASSESSOR: 33, //Visualizar lista de corretores da assessoria
        // Parametrização
        PARAMETRIZAR_SEGURADORA: 26,
        // Parametrização
         GERENCIAR_INTEGRACOES: 41,
         VISUALIZAR_INTEGRACOES : 40,
        // Gerenciamento erros consula
        GERENCIAR_CONSULTAS_BUREAU:42,
        BLOQUEIO_APOLICE_RENOVACAO:46
    });
}

export default new PerfilHelpers();
