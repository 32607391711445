import instance from "../../axios";

export default {
  namespaced: true,
  state: {
    corretores: [],
    corretora: {},
    socios: []
  },
  mutations: {
    updateListaCorretores(state, data) {
      state.corretores = data;
    },
    updateCorretora(state, corretora) {
      state.corretora = corretora;
    },
    updateListaSocios(state, data) {
      state.socios = data;
    }
  },
  actions: {
    getCorretores({ commit }, params) {
      this.$onpoint.loadingModal("teste", () => {
        return instance
          .get("api/Broker/GetSearchBrokers")
          .then(response => response.data.Response)
          .then(
            data => commit("updateListaCorretores", data.Data) || data.Data
          );
      });
    },

    getHistory({}, id) {
      return instance
        .get(`/api/BrokerChangeHistory/Get?brokerId=${id}`)
        .then(response =>
          response.data.Response.map(item => {
            return {
              ...item,
              ChangedByName: (item.ChangesHistories ||
                item.LocationChangesHistories ||
                [])[0].ChangedByName
            };
          })
        );
    },

    searchCorretores({ commit }, paremeters) {
      return instance
        .get("api/Broker/GetSearchBrokers", {
          params: {
            searchValue: paremeters,
            IgnoreInactive: true
          }
        })
        .then(response => response.data.Response)
        .then(data => commit("updateListaCorretores", data.Data) || data.Data);
    },
    getComissoesCorretor({ commit }, value) {
      return instance
        .get(`api/Broker/GetBrokerProposalCommissions?brokerId=${value}`)
        .then(response => response.data.Response);
    },
    getNominatedPolicyHolders({ commit }, data) {
      return instance
        .get(
          `api/PolicyHolder/GetPolicyHoldersNominatedByBrokerUniqueId?brokerUniqueId=${data}`
        )
        .then(response => response.data.Response);
    },
    enableDisableBroker({ commit }, data) {
      return instance
        .post(`api/Broker/InverterBlocked`, data)
        .then(response => response.data.Response);
    },
    getDadosFinanceiros({ commit }, data) {
      return instance
        .get(`api/Broker/GetBrokerFinancialData?brokerUniqueId=${data}`)
        .then(response => response.data.Response);
    }
  },
  getters: {
    corretores(state) {
      return state.corretores;
    },
    socios(state) {
      return state.socios;
    }
  }
};
