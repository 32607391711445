import instance from "../axios";

class DocumentProvider {
  getDocuments() {
    return instance
      .get("/api/InsuranceCompany/GetRequestDocuments")
      .then(response => response.data.Response);
  }
  createDocument(data) {
    return instance({
      method: "post",
      url: "api/InsuranceCompany/CreateRequestDocument",
      data
    }).then(response => response.data.Response);
  }
  uploadDocument(data) {
    return instance({
      method: "post",
      url: "/api/Broker/UploadBrokerRequestDocumentArchive",
      data
    }).then(response => response.data.Response);
  }
  uploadDocumento(data) {
    return instance({
      method: "post",
      url: "api/InsuranceCompany/UploadRequestDocument",
      data
    }).then(response => response.data.Response);
  }
  getUrlFileDownload(id) {
    return instance
      .get(`/api/Application/GetUrlFileDownload?ArchiveUniqueId=${id}`)
      .then(data => (data.data || {}).Response)
      .catch(data => {
        if (data && data.response && data.response.data)
          return Promise.reject(data.response.data.Errors);

        return Promise.reject("Erro ao tentar executar a solicitação.");
      });
  }
  getDocumentByrId(id) {
    return instance
      .get(
        `/api/Broker/GetBrokerRequestDocumentArchives?BrokerRequestDocumentId=${id}`
      )
      .then(response => response.data.Response);
  }
  editNameDocument(data) {
    return instance({
      method: "post",
      url: "api/InsuranceCompany/ChangeRequestDocumentName",
      data
    }).then(response => response.data.Response);
  }
  deleteDocument(data) {
    return instance({
      method: "post",
      url: "api/InsuranceCompany/DeleteRequestDocument",
      data
    }).then(response => response.data.Response);
  }
  restoreDocument(data) {
    return instance({
      method: "post",
      url: "api/InsuranceCompany/RestoreRequestDocument",
      data
    }).then(response => response.data.Response);
  }
  getRequestDocumentArchives(ref) {
    return instance
      .get(
        `/api/kanban/GetRequestDocumentArchives?RequestDocumentUniqueId=${ref}`
      )
      .then(data => (data.data || {}).Response)
      .catch(data => {
        if (data && data.response && data.response.data)
          return Promise.reject(data.response.data.Errors);
        return Promise.reject("Erro ao tentar executar a solicitação.");
      });
  }
  getArchiveLinkToDownload(ref) {
    return instance
      .get(`/api/Application/GetUrlFileDownload?ArchiveUniqueId=${ref}`)
      .then(data => (data.data || {}).Response)
      .catch(data => {
        if (data && data.response && data.response.data)
          return Promise.reject(data.response.data.Errors);

        return Promise.reject("Erro ao tentar executar a solicitação.");
      });
  }
}

export const documentProvider = new DocumentProvider();
