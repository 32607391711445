<template>
  <div class="help-desk"></div>
</template>
<script>
export default {
  created() {
    let recaptchaScript = document.createElement("script");
    recaptchaScript.setAttribute(
      "src",
      "https://chat.movidesk.com/Scripts/chat-widget.min.js"
    );
    document.head.appendChild(recaptchaScript);
  },
  beforeDestroy() {
    let element = document.getElementById("md-app-widget");
    element.parentNode.removeChild(element);
  }
};
</script>

<style>
#md-app-widget.md-chat-widget-wrapper {
  bottom: 0 !important;
}
#md-app-widget .md-chat-widget-icon-svg svg {
  fill: rgba(var(--vs-primary), 1) !important;
}

#md-app-widget .chat-mv-color-faded {
  color: rgba(var(--vs-primary), 1) !important;
}
#md-app-widget .chat-mv-color {
  background-color: rgba(var(--vs-primary), 1) !important;
  background: rgba(var(--vs-primary), 1) !important;
}
#md-app-widget .md-chat-widget-container {
  border-color: rgba(var(--vs-primary), 1) !important;
}

#md-app-widget .chat-mv-color-text-only {
  color: rgba(var(--vs-primary), 1) !important;
}
</style>
