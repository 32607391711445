var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vs-popup",
    {
      staticClass: "p-0 dialog",
      staticStyle: { width: "100% !important" },
      attrs: {
        title: "",
        "button-close-hidden": _vm.buttonCloseHidden,
        active: _vm.value,
        fullscreen: _vm.fullscreen,
      },
      on: {
        "update:active": function ($event) {
          _vm.value = $event
        },
        close: function ($event) {
          _vm.$emit("input", false)
          _vm.$emit("cancel")
        },
      },
    },
    [
      !!_vm.title
        ? [
            _c(
              "vs-row",
              [
                _c("h4", { staticClass: "vx-col md:w-11/12 w-full m-auto" }, [
                  _vm._v(_vm._s(_vm.title)),
                ]),
                _c("vs-button", {
                  staticClass: "vx-col md:w-1/12 w-full m-auto",
                  attrs: {
                    "vs-justify": "flex-end",
                    size: "large",
                    circle: "",
                    icon: "close",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("cancel")
                    },
                  },
                }),
              ],
              1
            ),
            _c("vs-divider", { staticClass: "mb-0" }),
          ]
        : _vm._e(),
      _c(
        "div",
        { staticClass: "pa-5" },
        [
          _c("div", { staticClass: "p-5" }, [_vm._t("content")], 2),
          _c(
            "vs-row",
            { attrs: { "vs-justify": "flex-end" } },
            [
              _vm._t("actions", function () {
                return [
                  _c(
                    "vs-button",
                    {
                      staticClass: "mr-2",
                      attrs: { color: "primary", type: "flat" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("cancel")
                        },
                      },
                    },
                    [
                      _c("span", { staticClass: "font-semibold" }, [
                        _vm._v(" Cancelar "),
                      ]),
                    ]
                  ),
                  _c(
                    "vs-button",
                    {
                      attrs: { color: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("confirm")
                        },
                      },
                    },
                    [
                      _c("span", { staticClass: "font-semibold" }, [
                        _vm._v(" Confirmar "),
                      ]),
                    ]
                  ),
                ]
              }),
            ],
            2
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }