import instance from "../../axios";

export default {
  namespaced: true,
  state: {
    segurado: {}
  },
  mutations: {},
  actions: {
    async getModality({ commit }, modalityId) {
      return await instance
        .get(`/api/Core/GetModality?modalityId=${modalityId}&ignoreCache=true`)
        .then(response => response.data.Response);
    },

    async getAllBranch() {
      return await instance
        .get("/api/Core/GetAllCompanyBranch")
        .then(response => response.data.Response);
    },

    async getModalitiesByBranch({ commit }, branchId) {
      return await instance
        .get(
          `/api/Core/GetModalitiesByBranch?branchId=${branchId}&ignoreCache=true`
        )
        .then(response => response.data.Response);
    },

    async getModalityObject({ commit }, data) {
      let filter = `?ModalityId=${data.modalityId}`;

      if (data.termType) filter += `&termType=${data.termType}`;

      return await instance
        .get(`/api/Core/GetModalityObject${filter}`)
        .then(response => response.data.Response);
    },

    async getAdditionalCoverages({ commit }, data) {
      data.ignoreDeleted =
        data.ignoreDeleted == null ? true : data.ignoreDeleted;
      return await instance
        .get(
          `/api/Core/GetAdditionalCoverages?ModalityUniqueId=${data.uniqueId}&ignoreCache=true&ignoreDeleted=${data.ignoreDeleted}`
        )
        .then(response => response.data.Response);
    },
    async getCardProposalParameterRequestDocument({ commit }) {
      return await instance
        .get(`/api/Core/GetCardProposalParameterRequestDocuments`)
        .then(response => response.data.Response);
    },
    async getProposalParameterRequestDocument({ commit }, uniqueId) {
      return await instance
        .get(
          `/api/Core/GetProposalParameterRequestDocuments?ModalityUniqueId=${uniqueId}&ignoreCache=true`
        )
        .then(response => response.data.Response);
    },

    async getParticularClauses({ commit }, data) {
      return await instance
        .get(
          `/api/Core/GetParticularDetailClauses?modalityid=${data.modalityId}&includedDeleted=true`
        )
        .then(response => response.data.Response);
    },
    async saveModalityDocument({ commit }, data) {
      return await instance
        .post(`/api/Core/InsertProposalParameterRequestDocument`, data)
        .then(response => response.data.Response);
    },
    async updateModalityDocument({ commit }, data) {
      return await instance
        .put(`/api/Core/UpdateProposalParameterRequestDocument`, data)
        .then(response => response.data.Response);
    },
    async deleteModalityDocument({ commit }, coverageId) {
      return await instance
        .delete(
          `/api/Core/DeleteProposalParameterRequestDocument?id=${coverageId}`
        )
        .then(response => response.data.Response);
    },

    async saveModalityObject({ commit }, data) {
      data.UniqueId = null;

      return await instance
        .put(`/api/Core/InsertContractualTerm`, data)
        .then(response => response.data.Response);
    },

    async saveParticularClauses({ commit }, data) {
      if (!data.Id) {
        return await instance
          .post(`/api/Core/InsertParticularClause`, data)
          .then(response => response.data.Response);
      }
      return await instance
        .put(`/api/Core/UpdateParticularClause`, data)
        .then(response => response.data.Response);
    },
    async updateModality({ commit }, data) {
      return await instance
        .put(`/api/Core/UpdateModality`, data)
        .then(response => response.data.Response);
    },
    async saveModality({ commit }, data) {
      return await instance
        .post(`/api/Core/InsertModality`, data)
        .then(response => response.data.Response);
    },
    async getComplementaryModality({ commit }, modalityUniqueId) {
      return await instance
        .get(
          `/api/Core/GetComplementaryModalities?ModalityUniqueId=${modalityUniqueId}&ignoreCache=true&ignoreDeleted=false`
        )
        .then(response => response.data.Response);
    },
    async saveModalityCoverage({ commit }, data) {
      return await instance
        .post(`/api/Core/InsertCoverage`, data)
        .then(response => response.data.Response);
    },
    async updateModalityCoverage({ commit }, data) {
      return await instance
        .put(`/api/Core/UpdateCoverage`, data)
        .then(response => response.data.Response);
    },
    async enableDisableModalityCoverage({ commit }, coverageId) {
      return await instance
        .delete(`/api/Core/EnableDisableCoverage?id=${coverageId}`)
        .then(response => response.data.Response);
    },
    async updateStatusParticularClause({ commit }, data) {
      return await instance
        .put(`/api/Core/UpdateStatusParticularClause`, data)
        .then(response => response.data.Response);
    },

    async insertComplementaryModality({ commit }, data) {
      return await instance
        .post(
          `/api/Core/InsertComplementaryModality?modalityId=${data.modalityId}&complementaryModalityId=${data.complementaryModalityId}`
        )
        .then(response => response.data.Response);
    },

    async enableDisableComplementaryModality({ commit }, complementaryModalityId) {
      return await instance
        .delete(
          `/api/Core/EnableDisableComplementaryModality?complementaryModalityId=${complementaryModalityId}`
        )
        .then(response => response.data.Response);
    },

    async getPercentageLimitPremiumRelationInsuredAmount({}) {
      return await instance
        .get(`/api/Core/GetPercentageLimitPremiumRelationIssuredAmount`)
        .then(response => response.data.Response);
    },

    async getAdditionalCoveragesObjects({ commit }, data) {
      return await instance
        .post(`/api/Core/GetAdditionalCoveragesObjects`, data)
        .then(response => response.data.Response);
    },

    async getBankList({ commit }) {
      return await instance
        .get(`/api/Core/GetBanks`)
        .then(response => response.data.Response);
    },
    async uploadDocumentos({ commit }, data) {
      var formData = new FormData();
      data.forEach(file => formData.append("file", file));

      return await instance
        .post("api/Archive/Upload", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => response.data.Response);
    }
  },
  getters: {}
};
