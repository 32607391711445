import Vue from "vue";
import OnPointErrorModal from "./index.vue";

const onPointErrorModalConstructor = Vue.extend(OnPointErrorModal);

function closeOthers(elx) {
  let errorModals;

  if (elx instanceof Element) {
    // Mimicking the behavior of doing `elx.querySelectorAll('> con-vs-loading')` but `>` is not well supported.
    // We are doing this because we can only add the respective classes to .con-vs-loading
    errorModals = Array.from(elx.children).filter(el => el.classList.contains("onpoint-errormodal"));
  } else {
    errorModals = document.querySelectorAll(elx || "body > .onpoint-errormodal");
  }

  errorModals
    .forEach((errorModal) => {
      errorModal.classList.add("beforeRemove");
      setTimeout(() => {
        errorModal.remove();
      }, 200);
    });
}

export default {
  name: "errorModal",
  onPointFunction(message) {
    const instance = new onPointErrorModalConstructor();
    const containerx = document.body;
    closeOthers();
    if (message) {
      instance.$data.message = message;
    }
    instance.vm = instance.$mount();
    containerx.insertBefore(instance.vm.$el, containerx.firstChild);
  }
};
