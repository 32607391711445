import Vue from "vue";

let totalInstancias = 0;
let instance = null;
export default {
  name: "loading",
  onPointFunction(fn) {
    const anonymoutComponent = Vue.extend({});
    if (totalInstancias <= 0) {
      instance = new anonymoutComponent();
      instance.$vs.loading();
    }
    totalInstancias += 1;
    return fn().then(() => {
        totalInstancias -= 1;
        if (totalInstancias <= 0) instance.$vs.loading.close();
      })
      .catch((ex) => {
        totalInstancias -= 1;
        if (totalInstancias <= 0) instance.$vs.loading.close();
      });
  }
};
