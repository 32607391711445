import instance from "../../axios";

export default {
  namespaced: true,
  state: {
    tabPagamentoParameter: {},
    pagamento: [],
    tabsPagamento: [],
    modalitiesGroups: [],

    generalConditions: {},
    tabsGeneralConditions: [],
    bureaus: [],
    permissions: [],
    listaBureaus: []
  },
  mutations: {
    updatePagamento(state, data) {
      state.pagamento = data;
    },

    updateTabsPagamento(state) {
      state.tabsPagamento = state.pagamento.map(function(item) {
        return {
          tab: item.Code,
          label: item.Name,
          data: item.InstallmentParameter
        };
      });
    },

    updateTabPagamentoParameter(state, data) {
      state.tabPagamentoParameter = data;
    },

    updateModalitiesGroups(state, data) {
      state.modalitiesGroups = data;
    },

    setModalities(state, data) {
      state.modalitiesGroups.forEach(grupo => {
        if (grupo.ModalityGroupUniqueId == data.uniqueId) {
          grupo.Modalities = data.response;
        }
      });
    },

    updateGeneralConditions(state, data) {
      state.generalConditions = data;
    },

    updateTabsGeneralConditions(state) {
      state.tabsGeneralConditions = state.generalConditions.ContractualTerms.map(function(item) {
        let isPrivate = state.generalConditions.PrivateContractualTermId == item.Id;
        return {
          tab: isPrivate ? "privado" : "publico",
          label: isPrivate ? "Privado" : "Público",
          data: item
        };
      });
    },

    updateTabHistoryGeneralConditions(state, data) {
      state.tabsGeneralConditions = state.tabsGeneralConditions.filter(x => x.tab !== 'history');
      if (data)
        state.tabsGeneralConditions = [...state.tabsGeneralConditions, {
          tab: 'history',
          label: "Histórico de alterações",
          data: data
        }]
    },

    updateTabsGeneralConditionsChanged(state, data) {
      state.tabsGeneralConditions.forEach(content => {
        if (content.tab == data.tab) content.data = data.response
      });
    },

    updateBureaus(state, data) {
      state.bureaus = data;
    },

    updateBureauQueryList(state, data) {
      state.bureaus.ListaConstultas.forEach((consulta) => {
        if(consulta.TipoConsultaId === data.TipoConsultaId) {
          consulta.ParametrosBureaus = data.ParametrosBureaus
        }
      })
    },

    updatePermissions(state, data) {
      state.permissions = data;
    },

    updateListaBureaus(state, data) {
      state.listaBureaus = data;
    },
  },
  actions: {
    async getHistoryModality({}, id) {
      return await instance
      .get(`/api/ModalityChangeHistory/GetModalityChangeHistory?modalityId=${id}`)
        .then(response =>
          response.data.Response.map(item => {
            return {
              ...item,
              ChangedByName: (item.ModalityChangesHistories ||
                item.LocationChangesHistories || [])[0].ChangedByName
            };
          })
        );
    },
    async getInstallmentParameter({ commit }) {
      return await instance.get(`api/InstallmentParameter/GetInstallmentParameter`)
        .then(response => response.data.Response)
        .then(response => commit("updatePagamento", response) || response)
        .then(() => commit("updateTabsPagamento"));
    },

    async getModalitiesGroups({ commit, getters }) {
      if (!getters.modalitiesGroups || !getters.modalitiesGroups.length)
        return await instance.get(`api/Modality/GetModalitiesGroups`)
          .then(response => response.data.Response)
          .then(response => commit("updateModalitiesGroups", response) || response);
    },

    async getModalitiesByGroup({ commit, getters }, payload) {
      let modalities = getModalities(getters.modalitiesGroups, payload);

      if (!modalities)
        return await instance.get(`api/Modality/GetModalitiesByGroup?modalityGroupUniqueId=${payload}`)
          .then(response => response.data.Response)
          .then(response => commit("setModalities", { response: response, uniqueId: payload }) || response);
    },

    async createInstallmentParameter({ commit }, payload) {
      return await instance.post(`api/InstallmentParameter/CreateInstallmentParameter`, payload)
        .then(response => response.data.Response)
    },

    async changeInstallmentParameter({ commit }, payload) {
      return instance.put(`api/InstallmentParameter/ChangeInstallmentParameter`, payload)
        .then(response => response.data.Response)
    },

    async changeInstallmentModalityParameter({ commit }, payload) {
      return await instance.post(`api/InstallmentModalityParameter/ChangeInstallmentModalityParameter`, payload)
        .then(response => response.data.Response)
    },

    async deleteInstallmentModalityParameter({ commit }, payload) {
      return await instance.delete(`api/InstallmentModalityParameter/DeleteInstallmentModalityParameter`, { data: payload.data })
        .then(response => response.data.Response)
    },

    //Condições Gerais
    async getGeneralConditions({ commit }) {
      return await instance.get('api/Plug/GetGeneralConditions ')
        .then(response => commit("updateGeneralConditions", response.data.Response) || response)
        .then(() => commit("updateTabsGeneralConditions"));
    },

    async getHistoryGeneralConditions({ commit }) {
      return await Promise.resolve([{
          User: 'lala',
          CreateDate: '2022-01-01T14:31:00',
          BranchName: 'Privado',
          TextNew: "Texto novo",
          TextOld: "Teste texto Publico"
        }])
        .then(response => commit("updateTabHistoryGeneralConditions", response) || response);


      return instance.get('api/Plug/GetHistoryGeneralConditions ')
        .then(response => commit("updateTabHistoryGeneralConditions", response) || response);
    },

    //Documentos da propsta por modalidade e esteira
    async getProposalParameterRequestDocuments({ commit }, payload) {
      return await instance.get('api/Core/GetProposalParameterRequestDocuments', {
          params: {
            modalityUniqueId: payload,
            ignoreCache: true
          }
        })
        .then(response => response.data.Response || response)

    },

    async getPipeTypeForDocument({ commit }, payload) {
      return await instance.get('api/Core/GetPipeTypeForDocument', {
          params: {
            modalityUniqueId: payload,
            ignoreCache: true
          }
        })
        .then(response => response.data.Response || response)
    },

    async saveModalityDocument({ commit }, data) {
      return await instance
        .post(`/api/Core/InsertProposalParameterRequestDocument`, data)
        .then(response => response.data.Response);
    },

    async updateStatusModalityDocument({ commit }, data) {
      return await instance
        .post(`/api/Core/UpdateStatusProposalParameterRequestDocument?id=${data.id}&IsDeleted=${data.isDeleted}`)
        .then(response => response.data.Response);
    },

    async getBureaus({ commit }) {
      return await instance.get(`api/Parametros/ObterParametrosConsultasPorBureau`)
        .then(response => response.data.Response)
        .then(response => commit("updateBureaus", response))
    },

    async getBureausListByTipoConsultaId({ commit }, tipoConsultaId) {
      return await instance.get(`api/Parametros/ObterBureausPorConsulta?TipoConsultaId=${tipoConsultaId}`)
        .then(response => response.data.Response)
        .then(response => commit("updateListaBureaus", response))
    },

    async addBureauQuery({commit}, data) {
      return await instance
      .post(`api/Parametros/AdicionarParametrosConsultasPorBureau`, data)
      .then(response => response.data.Response)
    },

    async deleteBureauQuery({commit}, data) {
      return await instance
      .post(`api/Parametros/DeletarParametrosConsultasPorBureau?BureauParametrizacaoConsultasId=${data.Id}`)
      .then(response => response.data.Response)
    },

    async saveBureauChanges({commit}, data) {
      return await instance
      .post(`api/Parametros/AtualizarParametrosConsultasPorBureau`, data)
      .then(response => response.data.Response)
    },

    async getPermissions({ commit }, module) {
      return await instance.get(`api/Permissao/BuscarPermissoes?Modulo=${module}`)
        .then(response => response.data.Response.Acao)
        .then(response => commit("updatePermissions", response))
    },
  },
  getters: {
    pagamento(state) {
      return state.pagamento;
    },

    tabsPagamento(state) {
      return state.tabsPagamento;
    },

    tabPagamentoParameter(state) {
      return state.tabPagamentoParameter;
    },

    modalitiesGroups(state) {
      return state.modalitiesGroups;
    },

    tabsGeneralConditions(state) {
      return state.tabsGeneralConditions;
    },

    bureaus(state) {
      return state.bureaus;
    },

    listaBureaus(state){
      return state.listaBureaus;
    },

    listaConsultas(state){
      return (state.bureaus || {}).ListaConstultas;
    },

    permissions(state) {
      return state.permissions;
    }
  }
};


function getModalities(modalitiesGroups, uniqueId) {
  modalitiesGroups.forEach(grupo => {
    if (grupo.ModalityGroupUniqueId == uniqueId && grupo.Modalities && grupo.Modalities.length) return grupo.Modalities
  });

  return false;
}
