import Vue from "vue";

let totalInstancias = 0;
let instance = null;

export default {
  name: "loadingMultipleRequests",
  async onPointFunction(functions) {
    const anonymoutComponent = Vue.extend({});
    if (totalInstancias <= 0) {
      instance = new anonymoutComponent();
      instance.$vs.loading();
    }
    totalInstancias += 1;

    if (!Array.isArray(functions)) {
      functions = [functions];
    }

    try {
      const results = await Promise.all(functions.map(fn => fn()));
      totalInstancias -= 1;
      if (totalInstancias <= 0) instance.$vs.loading.close();
      return results;
    } catch (error) {
      totalInstancias -= 1;
      if (totalInstancias <= 0) instance.$vs.loading.close();
      throw error;
    }
  }
};
