<template>
  <popup-base
    class="error-modal onpoint-errormodal modal-z"
   
    size="sm"
    title=" "
    @close="active = false"
    :showModal="active"
  >
    <template slot="content">
      <div class="flex flex-col items-center pt-4">
        <h2 class="flex flex-col items-center text-center w-1/2">
          <vs-icon color="danger" class="mb-6" icon="error"></vs-icon>
          Atenção!
        </h2>

        <p
          class="text-center my-8 mx-2"
          style="font-size: 20px;"
          v-if="!message || typeof message == 'string'"
        >
          <b>
            {{
              message ||
                "Algo inesperado aconteceu! Por favor contate o administrador do sistema."
            }}</b
          >
        </p>
        <div v-if="Array.isArray(message)">
          <p
            class="text-center my-8 mx-2"
            style="font-size: 20px;"
            v-for="msg in message"
            :key="msg"
          >
            <b> {{ msg }}</b>
          </p>
        </div>
        <slot></slot>
      </div>
    </template>
  </popup-base>
</template>
<script>
export default {
  name: "error-popup",
  data: () => ({
    active: true,
    message: ""
  }),
  watch: {
    active(val) {
    }
  }
};
</script>
<style lang="scss">
.error-modal {
  ::v-deep .vs-popup--header {
    background: white;
  }

  .footer-popup-base {
    display: none;
  }

  &.popup-base .vs-popup .vs-popup--header {
    padding-bottom: 0px !important;
    height: 0px;
  }

  h2 {
    i {
      font-size: 2em;
    }
  }

  h2,
  p {
    color: initial;
  }
}
</style>
