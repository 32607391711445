import Vue from "vue";
import OnPointLoadingModal from "./index.vue";

const onPointLoadingModalConstructor = Vue.extend(OnPointLoadingModal);
let activeRequests = 0;

function closeOthers(elx) {
  let elements;

  if (elx instanceof Element) {
    // Mimicking the behavior of doing `elx.querySelectorAll('> con-vs-loading')` but `>` is not well supported.
    // We are doing this because we can only add the respective classes to .con-vs-loading
    elements = Array.from(elx.children).filter(el => el.classList.contains("onpoint-loadingmodal"));
  } else {
    elements = document.querySelectorAll(elx || "body > .onpoint-loadingmodal");
  }

  elements
    .forEach((element) => {
      element.classList.add("beforeRemove");
      setTimeout(() => {
        element.remove();
      }, 200);
    });
}

export default {
  name: "loadingModal",
  onPointFunction(message, fn) {
    const instance = new onPointLoadingModalConstructor();
    const containerx = document.body;

    activeRequests++;
    closeOthers();

    if (message) {
      instance.$data.message = message;
    }
    instance.vm = instance.$mount();
    containerx.insertBefore(instance.vm.$el, containerx.firstChild);

    fn()
      .finally(() => {
        activeRequests--;
        if (activeRequests === 0) {
          closeOthers();
        }
      })
  }
};
