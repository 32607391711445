import instance from "../../axios";

export default {
  namespaced: true,
  state: {
    filial: {}
  },
  mutations: {
    updateFilial (state, filial) {
      state.filial = filial;
    }
  },
  actions: {
    async cadastrarFilial ({ commit }, data) {
      return await instance
        .post(`api/PolicyHolder/InsertPolicyHolderBranch?PolicyHolderUniqueId=${data.uniqueId}&BranchCnpj=${data.cnpj}`)
        .then(response => {
          return response.data.Response;
        })
        .then(data => commit("updateFilial", data) || data);
    },

    async validaFilialPorCnpj ({ commit }, cnpj) {
      return await instance
        .post(`api/PolicyHolder/ValidatePolicyHolderByCNPJToNewProposal?Cnpj=${cnpj}`)
        .then(response => {
          return response.data.Response;
        })
        .then(data => commit("updateFilial", data) || data);
    },

    async buscaFilialPorTexto ({ commit }, texto) {
      return await instance
        .get(`/api/PolicyHolder/SearchPolicyHolder?TextSearch=${texto}`)
        .then(response => {
          return response.data.Response;
        });
    },

    async deletarFilial ({ commit }, uniqueId) {
      return await instance.delete(`/api/PolicyHolder/DeletePolicyHolderBranchByUniqueId?uniqueId=${uniqueId}`)
        .then(response => {
          return response.data.Response;
        });
    }
  },
  getters: {
    filial (state) {
      return state.filial;
    }
  }
};
