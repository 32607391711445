import Vue from "vue";

Vue.filter("iconeTimeline", function (value) {
  switch (value) {
  case 1 : return "done";
  case 2 : return "info";
  case 3 : return "warning";
  case 4 : return "danger";
  default : return "";
  }
});
