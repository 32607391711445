/**
 *O identity server não está pronto ainda. Foi preciso criar essa instancia do Axios para não impactar no que está funcionado atualmente.
 */
// axios
import axios from "axios";
import environment from "../environment";
import store from "../store/index";

const token = function() {
  return store.getters["auth/token"];
};
const userInfo = function() {
  return store.getters["auth/userInfo"];
};
const applicationConfig = environment.getApplicationConfig();
const companyKey = (applicationConfig || {}).companyKey;

const instance = axios.create({
  baseURL: ((applicationConfig || {}).kanban || {}).url,
  headers: {
    companyKey,
    "Content-Type": "application/json"
  },
  params: {}
});

instance.interceptors.request.use(function(config) {
  const tokenVal = token();
  const userInfoVal = userInfo();
  if (tokenVal) config.headers.Authorization = `Bearer ${tokenVal}`;
  config.headers.BrokerId = userInfoVal.BrokerId || "";
  config.headers.UserId = userInfoVal.UserId || "";

  return config;
}, function(error) {
  return Promise.reject(error);
});

instance.interceptors.response.use(function(response) {
  if (response != undefined && response.data.success === false) {
    const errors = [response.data.message];
    return Promise.reject(errors);
  } else if (response && response.data && response.data.data) {
    return Promise.resolve(response.data.data);
  }
  return response;
}, function(error) {
  if (error.response) {
    const response = error.response;
    if (response != undefined && response.status === 401) {
      //EventEmitter.emit(environment.events.notAuthenticated, response.data);
    }
    return Promise.reject(error);
  } else {
    return Promise.reject([error.response.data.message]);
  }
});

export default instance;