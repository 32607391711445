var render = function render(_c, _vm) {
  return _c(
    "div",
    { staticClass: "vx-input-group flex", class: _vm.data.staticClass },
    [
      _vm.slots().prepend
        ? _c(
            "div",
            {
              staticClass: "vx-input-group-prepend flex",
              class: _vm.props.prependClasses,
            },
            [_vm._t("prepend")],
            2
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "vx-input-group-default flex-grow" },
        [_vm._t("default")],
        2
      ),
      _vm.slots().append
        ? _c(
            "div",
            {
              staticClass: "vx-input-group-append flex",
              class: _vm.props.appendClasses,
            },
            [_vm._t("append")],
            2
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }