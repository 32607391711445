import Vue from "vue";
import Vuex from "vuex";
import actions from "./actions";
import getters from "./getters";
import modules from "./modules";
import mutations from "./mutations";
import state from "./state";
// flatten modules
let _modules = Object.keys(modules).reduce(function(r, k) {
  return r.concat(modules[k]);
}, []);

Vue.use(Vuex);

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== "production",
  getters: getters,
  mutations: mutations,
  state: state,
  actions: actions,
  modules: modules,
  // getters,
});