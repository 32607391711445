import instance from "@/axios";

export default {
    namespaced: true,
    state: {},
    mutations: {},
    actions: {
        searchBeneficiary({ commit }, payload) {
            const url = `TextSearch=${payload.textSearch}&CanHaveBeneficiaryPF=${payload.allowedPF}`;
            return instance
                .get(
                    `/api/Beneficiary/SearchBeneficiary?${url}`
                )
                .then(response => response.data.Response);
        },

        validateBeneficiaryByDocument({ commit }, payload) {
            return instance
                .post("/api/Beneficiary/ValidateBeneficiaryByDocument", payload)
                .then(response => response.data.Response);
        },

        saveBeneficiaryLocation({ commit }, payload) {
            return instance
                .post(`/api/Beneficiary/SaveBeneficiaryLocation`, payload)
                .then(response => response.data.Response);
        }
    },
    getters: {}
};