<template>
  <popup-base
    @close="active = false"
    :showModal="active"
    size="sm"
    class="success-modal onpoint-successmodal"
    title=" "
    :button-close-hidden="false"
  >
    <template slot="content">
      <div class="flex flex-col items-center pt-4">
        <h2 class="flex flex-col items-center text-center w-1/2">
          <vs-icon color="success" class="mb-6" icon="check_circle"></vs-icon>
          Sucesso
        </h2>
        <p
          class="text-center my-8 mx-2"
          v-if="!message || typeof message == 'string'"
        >
          {{ message || "Operação realizada com sucesso!" }}
        </p>
        <div v-if="Array.isArray(message)">
          <p class="text-center my-8 mx-2" v-for="msg in message" :key="msg">
            {{ msg }}
          </p>
        </div>
        <slot></slot>
      </div>
    </template>
  </popup-base>
</template>
<script>
export default {
  name: "success-popup",
  data: () => ({
    active: true,
    message: ""
  })
};
</script>
<style lang="scss">
.success-modal {
  ::v-deep .vs-popup--header {
    background: white;
  }

  h2 {
    i {
      font-size: 2em;
    }
  }

  h2,
  p {
    color: initial;
  }
}
</style>
