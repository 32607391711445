import instance from "../../axios";

export default {
    namespaced: true,
    state: {
        usuario: {},
        usuarios: {},
        tiposUsuario: []
    },
    mutations: {
        updateUsuario(state, usuario) {
            state.usuario = usuario;
        },

        updateTiposUsuario(state, tiposUsuario) {
            state.tiposUsuario = tiposUsuario;
        },

        listaUsuarios(state, usuarios) {
            state.usuarios = usuarios;
        },

        setIsActiveUserList(state, userId) {
            let user = state.usuarios.find(user => user.UserInfoId == userId);
            let index = state.usuarios.indexOf(user);

            user.IsActive = !user.IsActive;
            state.usuarios[index] = user;
        }
    },
    actions: {
       async obeterUsuarioUniqueId({ commit }, uniqueId) {
            return await instance
                .get(`/api/Security/GetUserInfoByUniqueId?UniqueId=${uniqueId}`)
                .then(response => response.data.Response)
                .then(data => commit("updateUsuario", data) || data)
                .then(() => this.usuario);
        },

        async deletarUsuario({ commit }, id) {
            return await instance({
                    method: "delete",
                    url: `/api/Person/DeleteUserInfo?userInfoId=${id}`
                })
                .then(response => {
                    return response.data.Response;
                })
                .then(data => commit("updateUsuario", data) || data);
        },

        async obterTipoDeAcesso({ commit }, payload = null) {
            return await instance
                .get(`/api/UserProfile/GetSettableProfiles?userInfoTypeId=${payload}`)
                .then(response => response.data.Response)
                .then(data => commit("updateTiposUsuario", data))
                .then(() => this.tiposUsuario);
        },

        async obterUsuarios({ commit }, payload = {}) {
            return  await instance
                .get(
                    `/api/Person/GetUsers?filterByActive=${payload.filterByActive}&brokerId=${payload.brokerId}`
                )
                .then(response => response.data.Response)
                .then(data => commit("listaUsuarios", data) || data);
        },

        async criarUsuario({ commit }, usuario) {
            return  await instance({
                    method: "post",
                    url: "/api/Person/SaveUserInfo",
                    data: usuario
                })
                .then(response => {
                    return response.data.Response;
                })
                .then(data => commit("updateUsuario", data) || data);
        },

        async inativarAtivarUsuario({ commit }, usuario) {
            return await instance({
                    method: "post",
                    url: `/api/Person/ChangeUserInfoStatus?userId=${usuario}`
                })
                .then(response => {
                    return response.data.Response;
                })
                .then(data => commit("setIsActiveUserList", usuario) || data);
        }
    },
    getters: {
        usuarios(state) {
            return state.usuarios;
        },

        usuario(state) {
            return state.usuario;
        },

        tiposUsuario(state) {
            return state.tiposUsuario;
        },

        usuariosPerfil(state) {
            let usersGroupByProfiles = [];
            (state.usuarios || []).forEach(element => {
                if (!usersGroupByProfiles[element.ProfileUniqueId])
                    usersGroupByProfiles[element.ProfileUniqueId] = [];

                usersGroupByProfiles[element.ProfileUniqueId].push(element);
            });

            return usersGroupByProfiles;
        }
    }
};