<template>
  <vs-popup
    :title="title"
    :active.sync="popupBase"
    :style="style"
    :fullscreen="fullscreen"
    :button-close-hidden="buttonCloseHidden"
    :class="['popup-base', fullscreen ? '' : size]"
  >
    <div class="content-popup-base">
      <slot name="content"></slot>
    </div>

    <div class="footer-popup-base">
      <slot name="footer"></slot>
    </div>
  </vs-popup>
</template>

<script>
export default {
  name: "popup-base",
  data() {
    return { style: "" };
  },
  props: {
    size: { type: String, default: "sm" },
    title: String,
    showModal: { type: Boolean, default: false },
    fullscreen: { type: Boolean, default: false },
    buttonCloseHidden: { type: Boolean, default: false }
  },
  computed: {
    popupBase: {
      get() {
        if (this.showModal) {
          const listPopup = document.getElementsByClassName("popup-base");
          let currentStyle = 0;

          if (listPopup && listPopup.length) {
            const listPopupVisible = [];

            listPopup.forEach(popup => {
              if (popup.style.display != "none") listPopupVisible.push(popup);
            });

            listPopupVisible.forEach(popup => {
              if (window.getComputedStyle) {
                const zIndex = parseInt(
                  document.defaultView.getComputedStyle(popup, null).zIndex
                );

                if (zIndex > currentStyle) currentStyle = zIndex;
              }
            });

            if (currentStyle)
              this.style = `z-index: ${currentStyle + 1} !important`;
          }
        }

        return this.showModal;
      },

      set(value) {
        this.$emit("close");
      }
    }
  }
};
</script>

<style lang="scss">
.popup-base {
  .vs-popup--title h3 {
    font-size: 1.5em !important;
  }
}

.popup-base {
  .vs-popup {
    display: block;
    padding: 0 0 !important;

    .vs-popup--header {
      padding: 30px !important;
      margin: 10px 0;
      height: 60px;

      .vs-popup--title,
      .vs-popup--title * {
        transform: unset !important;
        padding: 0 !important;
        margin: 0 !important;
      }

      .vs-popup--close {
        transform: translate(16px, -16px) scale(0.7) !important;
        background: transparent;
        font-size: 30px;
        box-shadow: 0 0;
      }
    }

    .vs-popup--content {
      width: 100%;
      padding: 0 0 10px !important;
      margin: 0 !important;

      .content-popup-base {
        max-height: 50%;
        padding: 0 30px 15px;
        overflow-y: auto;
        overflow-x: hidden;
      }

      .footer-popup-base {
        padding: 0 30px;
        height: 40px;
        width: 100%;
        margin-top: 5px;
      }
    }
  }
}

.popup-base:not(.fullscreen) {
  .vs-popup {
    @media (min-width: 610px) {
      max-height: 80vh;

      .vs-popup--content {
        max-height: 65vh;

        .content-popup-base {
          max-height: 57vh;
        }
      }
    }

    @media (max-width: 609px) {
      .vs-popup--content {
        max-height: 85vh;

        .content-popup-base {
          max-height: 79vh;
        }
      }
    }
  }
}

@media (min-width: 1000px) {
  .xs .vs-popup {
    width: 20%;
    max-width: 317px !important;
    .vs-popup--title {
      display: none;
    }
  }
  .sm .vs-popup {
    width: 40%;
    max-width: 562px !important;
  }
  .md .vs-popup {
    width: 50%;
    max-width: 685px !important;
  }
  .lg .vs-popup {
    width: 60%;
    max-width: 807px !important;
  }
  .lx .vs-popup {
    width: 70%;
    max-width: 1052px !important;
  }
}

@media (max-width: 999px) and (min-width: 800px) {
  .xs,
  .sm,
  .md {
    .vs-popup {
      width: 100% !important;
    }
  }

  .lg,
  .lx {
    .vs-popup {
      width: 100% !important;
    }
  }
}

@media (max-width: 250px) {
  .xs,
  .sm,
  .md,
  .lg,
  .lx {
    .vs-popup {
      width: 100% !important;
    }
  }
}
</style>
