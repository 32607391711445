import extenso from "extenso";
import moment from "moment";
import { formatStrMoney, reaisToExtenso, maskDocument } from "../utils/utils";

class TagHelpers {
    // formatStrMoney(amount, decimalCount = 2, decimal = ".", thousands = ",") {
    //   try {
    //     decimalCount = Math.abs(decimalCount);
    //     decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    //     const negativeSign = amount < 0 ? "-" : "";

    //     const i = parseInt(
    //       (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
    //     ).toString();
    //     const j = i.length > 3 ? i.length % 3 : 0;

    //     return (
    //       negativeSign +
    //       (j ? i.substr(0, j) + thousands : "") +
    //       i.substr(j).replace(/(\d{3})(?=\d)/g, `$1${thousands}`) +
    //       (decimalCount ?
    //         decimal +
    //         Math.abs(amount - i)
    //         .toFixed(decimalCount)
    //         .slice(2) :
    //         "")
    //     );
    //   } catch (e) {}
    // }

    replaceDefaultTags(text, defaultTags, ignoreTags) {
        if (!text || !defaultTags || defaultTags.length == 0) return text;

        defaultTags.forEach(tag => {
            if (ignoreTags && ignoreTags.indexOf(tag.Name) >= 0) return;
            const value = tag.Value || "";
            const tagName = `[${tag.Name}]`;
            if (text.includes(tagName)) {
                text = text.replaceAll(
                    `[${tag.Name}]`,
                    `<span id='${tag.Name}' class="font-semibold text-danger">${value}</span>`
                );
            }
        });
        return text;
    }

    replaceTags(tagList, textHtml, objeto) {
        //set tag
        tagList.forEach(tag => {
            if (tag.Label)
                textHtml = textHtml.replaceAll(
                    `[${tag.Name}]`,
                    `<span id='${tag.Name}' class='font-semibold text-danger'>[${tag.Label}]</span>`
                );
            else
                textHtml = textHtml.replaceAll(
                    `[${tag.Name}]`,
                    `<span id='${tag.Name}' class='font-semibold text-danger'>[${tag.Name}]</span>`
                );
        });

        tagList.forEach(tag => {
            if (!!objeto[tag.Name]) {
                if (tag.Label)
                    textHtml = textHtml.replaceAll(
                        `<span id='${tag.Name}' class='font-semibold text-danger'>[${tag.Label}]</span>`,
                        `<span id='${tag.Name}' class='font-semibold text-danger'>${objeto[tag.Name]}</span>`
                    );
                else
                    textHtml = textHtml.replaceAll(
                        `<span id='${tag.Name}' class='font-semibold text-danger'>[${tag.Name}]</span>`,
                        `<span id='${tag.Name}' class='font-semibold text-danger'>${objeto[tag.Name]}</span>`
                    );
            }
        });

        return textHtml;
    }

    replaceTagsTextCompare(tagList, textHtml, objeto) {
        tagList.forEach(tag => {
            if (objeto[tag.Name]) {
                textHtml = textHtml.replaceAll(`[${tag.Name}]`, objeto[tag.Name]);
            }
        });

        return textHtml;
    }

    getPolicyHolderLocationMain(policyHolder) {
        if (!policyHolder ||
            !policyHolder.Locations ||
            policyHolder.Locations.length == 0
        )
            return null;
        const locationMain = policyHolder.Locations.find(
            location => location.IsMain == true
        );
        if (!locationMain) return null;
        return locationMain;
    }

    parseTagNumberValueExtenso(value, mode) {
        if (!value) return "";
        const number = parseFloat(value);
        if (!mode) return extenso(number);
        else {
            return reaisToExtenso(formatStrMoney(number, 2, ",", "."));
        }
    }

    defaultTags(proposta, seguradora = {}) {
        if (!proposta) return [];
        const locationMain = this.getPolicyHolderLocationMain(
            proposta.PolicyHolder
        );

        let diasVigencia = proposta.DeadlineDays;

        if (proposta.ComplementaryDeadlineDays)
            diasVigencia += proposta.ComplementaryDeadlineDays;
        return [{
            Name: "INI_VIGENCIA",
            Label: "Inicio da Vigência",
            Value:
                !proposta || !proposta.StartDate ?
                    null : moment(proposta.StartDate).format("DD/MM/YYYY")
        },

        {
            Name: "FIM_VIGENCIA",
            Label: "Fim da Vigência",
            Value:
                !proposta || !proposta.EndDate ?
                    null : moment(proposta.EndDate).format("DD/MM/YYYY")
        },
        {
            Name: "VALOR_IS",
            Label: "Valor IS",
            Value:
                !proposta || !proposta.InsuredAmountValue ?
                    null : `${formatStrMoney(proposta.InsuredAmountValue, 2, ",", ".")}`
        },
        {
            Name: "VALOR_IS_EXT",
            Label: "Valor IS por extenso",
            Value: this.parseTagNumberValueExtenso(
                proposta.InsuredAmountValue,
                "currency"
            )
        },
        {
            Name: "PRAZO_DIAS",
            Label: "Prazo em Dias",
            Value: proposta.DeadlineDays
        },
        {
            Name: "PRAZO_DIAS_EXT",
            Label: "Prazo em Dias por extenso",
            Value: this.parseTagNumberValueExtenso(proposta.DeadlineDays)
        },
        {
            Name: "PRAZO_DIAS_APOLICE",
            Label: "Prazo em dias da apólice",
            Value: diasVigencia
        },
        {
            Name: "PRAZO_DIAS_APOLICE_EXT",
            Label: "Prazo em dias da apólice por extenso",
            Value: this.parseTagNumberValueExtenso(diasVigencia)
        },
        {
            Name: "INI_VIGENCIA_COMPL",
            Label: "Inicio da Vigência Complementar",
            Value:
                !proposta ||
                    !proposta.ComplementaryModality ||
                    !proposta.ComplementaryModality.Id ||
                    proposta.ComplementaryModality.Id == 0 ?
                    null : moment(proposta.ComplementaryStartDate).format("DD/MM/YYYY")
        },

        {
            Name: "FIM_VIGENCIA_COMPL",
            Label: "Fim da Vigência Complementar",
            Value:
                !proposta || !proposta.ComplementaryEndDate ?
                    null : moment(proposta.ComplementaryEndDate).format("DD/MM/YYYY")
        },
        {
            Name: "VALOR_IS_COMPL",
            Label: "Valor IS Complementar",
            Value:
                !proposta || !proposta.ComplementaryInsuredAmountValue ?
                    null : `R$ ${formatStrMoney(
                        proposta.ComplementaryInsuredAmountValue,
                        2,
                        ",",
                        "."
                    )}`
        },
        {
            Name: "VALOR_IS_COMPL_EXT",
            Label: "Valor IS Complementar por extenso",
            Value: this.parseTagNumberValueExtenso(
                proposta.ComplementaryInsuredAmountValue,
                "currency"
            )
        },
        {
            Name: "PRAZO_DIAS_COMPL",
            Label: "Prazo em Dias Complementar",
            Value: proposta.ComplementaryDeadlineDays
        },
        {
            Name: "PRAZO_DIAS_COMPL_EXT",
            Label: "Prazo em Dias Complementar por extenso",
            Value: this.parseTagNumberValueExtenso(
                proposta.ComplementaryDeadlineDays
            )
        },
        {
            Name: "TOMADOR",
            Label: "Tomador",
            Value: proposta && proposta.PolicyHolder ? proposta.PolicyHolder.Name : null
        },
        {
            Name: "CNPJ_TOMADOR",
            Label: "Cnpj Tomador",
            Value: proposta && proposta.PolicyHolder ?
                maskDocument(proposta.PolicyHolder.CpfCnpj) : null
        },
        {
            Name: "ENDERECO_COMPLETO_TOMADOR",
            Label: "Endereco Completo Tomador",
            Value: !locationMain ? null : locationMain.Address
        },
        {
            Name: "ENDERECO_TOMADOR",
            Label: "Endereco Tomador",
            Value: !locationMain ? null : locationMain.AddressName
        },
        {
            Name: "CIDADE_TOMADOR",
            Label: "Cidade Tomador",
            Value:
                !locationMain || !locationMain.City ? null : locationMain.City.Name
        },
        {
            Name: "ESTADO_TOMADOR",
            Label: "Estado Tomador",
            Value:
                !locationMain || !locationMain.StateProvince ?
                    null : locationMain.StateProvince.Name
        },
        {
            Name: "BAIRRO_TOMADOR",
            Label: "Bairro Tomador",
            Value: !locationMain ? null : locationMain.Neighborhood
        },
        {
            Name: "NRO_TOMADOR",
            Label: "Nroº Tomador",
            Value: !locationMain ? null : locationMain.Number
        },
        {
            Name: "CEP_TOMADOR",
            Label: "Cep Tomador",
            Value: !locationMain ? null : locationMain.ZipCode
        },
        {
            Name: "SEGURADO",
            Label: "Segurado",
            Value: !proposta || !proposta.Insured ? null : proposta.Insured.Name
        },
        {
            Name: "CPF_CNPJ_SEGURADO",
            Label: "Cnpj Segurado",
            Value:
                !proposta || !proposta.Insured ?
                    null : maskDocument(proposta.Insured.CpfCnpj)
        },
        {
            Name: "ENDERECO_COMPLETO_SEGURADO",
            Label: "Endereco Completo Segurado",
            Value:
                !proposta || !proposta.InsuredLocation ?
                    null : proposta.InsuredLocation.Address
        },
        {
            Name: "ENDERECO_SEGURADO",
            Label: "Endereco Segurado",
            Value:
                !proposta || !proposta.InsuredLocation ?
                    null : proposta.InsuredLocation.AddressName
        },
        {
            Name: "BAIRRO_SEGURADO",
            Label: "Bairro Segurado",
            Value:
                !proposta || !proposta.InsuredLocation ?
                    null : proposta.InsuredLocation.Neighborhood
        },
        {
            Name: "CIDADE_SEGURADO",
            Label: "Cidade Segurado",
            Value:
                !proposta ||
                    !proposta.InsuredLocation ||
                    !proposta.InsuredLocation.City ?
                    null : proposta.InsuredLocation.City.Name
        },
        {
            Name: "ESTADO_SEGURADO",
            Label: "Estado Segurado",
            Value:
                !proposta ||
                    !proposta.InsuredLocation ||
                    !proposta.InsuredLocation.StateProvince ?
                    null : proposta.InsuredLocation.StateProvince.Name
        },
        {
            Name: "NRO_SEGURADO",
            Label: "Nroº Segurado",
            Value:
                !proposta || !proposta.InsuredLocation ?
                    null : proposta.InsuredLocation.Number
        },
        {
            Name: "CEP_SEGURADO",
            Label: "CEP Segurado",
            Value:
                !proposta || !proposta.InsuredLocation ?
                    null : proposta.InsuredLocation.ZipCode
        },
        {
            Name: "NOME_SEGURADORA",
            Label: "Nome da Seguradora",
            Value: seguradora["NOME_SEGURADORA"]
        },
        {
            Name: "CNPJ_SEGURADORA",
            Label: "Cnpj da Seguradora",
            Value: seguradora["CNPJ_SEGURADORA"]
        },
        {
            Name: "CIDADE_SEGURADORA",
            Label: "Cidade da Seguradora",
            Value: seguradora["CIDADE_SEGURADORA"]
        },
        {
            Name: "ESTADO_SEGURADORA",
            Label: "Estado da Seguradora",
            Value: seguradora["ESTADO_SEGURADORA"]
        },
        {
            Name: "CEP_SEGURADORA",
            Label: "Cep da Seguradora",
            Value: seguradora["CEP_SEGURADORA"]
        },
        {
            Name: "NRO_SEGURADORA",
            Label: "Nro da Seguradora",
            Value: seguradora["NRO_SEGURADORA"]
        },
        {
            Name: "RUA_SEGURADORA",
            Label: "Rua da Seguradora",
            Value: seguradora["RUA_SEGURADORA"]
        },
        {
            Name: "ESTADO_SEGURADORA",
            Label: "Endereço Completo da Seguradora",
            Value: seguradora["ENDERECO_COMPLETO_SEGURADORA"]
        },
        {
            Name: "BAIRRO_SEGURADORA",
            Label: "Bairro da Seguradora",
            Value: seguradora["BAIRRO_SEGURADORA"]
        }
        ];
    }

    defaultTagsEndosso(endosso, apolice, motivo) {
        console.log("endosso", endosso)
        console.log("apolice", apolice)
        let ObjectJSON = {
            start: null,
            end: null,
        };
        if (endosso.EndorsementTypeId == 1) {
            ObjectJSON.start = apolice.StartDate;
            ObjectJSON.end = apolice.EndDate;
        } else if (endosso.EndorsementTypeId == 2) {
            if (endosso.ObjectJSON) {
                ObjectJSON.start = JSON.parse(endosso.ObjectJSON).INI_VIGENCIA
                ObjectJSON.end = JSON.parse(endosso.ObjectJSON).FIM_VIGENCIA
            } else {
                ObjectJSON.start = moment(apolice.StartDate).format("DD/MM/YYYY");
                ObjectJSON.end = moment(apolice.EndDate).format("DD/MM/YYYY");
            }
        }
        return [{
            Name: "TAG_MOTIVO",
            Label: "Motivo do endosso",
            Value: motivo.ReasonEndorsementRequestDescription
        },
        {
            Name: "TAG_RAZAOSOCIAL_TOMADOR",
            Value: (endosso.PolicyHolder || {}).Name
        },
        {
            Name: "TAG_LOGRADOURO_TOMADOR",
            Value: ((endosso.PolicyHolder || {}).Location || {}).AddressName
        },
        {
            Name: "TAG_NUMERO_TOMADOR",
            Value: ((endosso.PolicyHolder || {}).Location || {}).Number
        },
        {
            Name: "TAG_COMPLEMENTO_TOMADOR",
            Value: (endosso.PolicyHolder || {} || {}).Complement
        },
        {
            Name: "TAG_BAIRRO_TOMADOR",
            Value: ((endosso.PolicyHolder || {}).Location || {}).Neighborhood
        },
        {
            Name: "TAG_CIDADE_TOMADOR",
            Value: ((endosso.PolicyHolder || {}).Location || {}).CityName
        },
        {
            Name: "TAG_ESTADO_TOMADOR",
            Value: ((endosso.PolicyHolder || {}).Location || {}).StateProvinceName
        },
        {
            Name: "TAG_CEP_TOMADOR",
            Value: ((endosso.PolicyHolder || {}).Location || {}).ZipCode
        },
        {
            Name: "TAG_COMPLEMENTO_TOMADOR",
            Value: ((endosso.PolicyHolder || {}).Location || {}).Complement || ""
        },
        {
            Name: "TAG_RAZAOSOCIAL_SEGURADO",
            Value: (endosso.Insured || {}).Name
        },
        {
            Name: "TAG_LOGRADOURO_SEGURADO",
            Value: ((endosso.Insured || {}).Location || {}).AddressName
        },
        {
            Name: "TAG_NUMERO_SEGURADO",
            Value: ((endosso.Insured || {}).Location || {}).Number
        },
        {
            Name: "TAG_COMPLEMENTO_SEGURADO",
            Value: ((endosso.Insured || {}).Location || {}).Complement
        },
        {
            Name: "TAG_BAIRRO_SEGURADO",
            Value: ((endosso.Insured || {}).Location || {}).Neighborhood
        },
        {
            Name: "TAG_CIDADE_SEGURADO",
            Value: ((endosso.Insured || {}).Location || {}).CityName
        },
        {
            Name: "TAG_ESTADO_SEGURADO",
            Value: ((endosso.Insured || {}).Location || {}).StateProvinceName
        },
        {
            Name: "TAG_CEP_SEGURADO",
            Value: ((endosso.Insured || {}).Location || {}).ZipCode
        },
        {
            Name: "TAG_COMPLEMENTO_SEGURADO",
            Value: ((endosso.Insured || {}).Location || {}).Complement || ""
        },
        {
            Name: "TAG_DATA_NOW",
            Value: moment().format("DD/MM/YYYY")
        },
        {
            Name: "TAG_DATA_INICIO_IS",
            Value: endosso.StartDate ? moment(endosso.StartDate).format("DD/MM/YYYY") : ObjectJSON.start
        },
        {
            Name: "TAG_IS_APOLICE",
            Value: formatStrMoney(apolice.InsuredAmountValue, 2, ",", ".")
        },
        {
            Name: "TAG_IS_ENDOSSO",
            Value: formatStrMoney(endosso.InsuredAmountValue, 2, ",", ".")
        },
        {
            Name: "TAG_IS_APOLICE_COMPLEMENTAR_ANTERIOR",
            Value: formatStrMoney(
                apolice.ComplementaryInsuredAmountValue,
                2,
                ",",
                "."
            )
        },
        {
            Name: "TAG_IS_APOLICE_COMPLEMENTAR_NOVA",
            Value: formatStrMoney(
                endosso.ComplementaryInsuredAmountValue,
                2,
                ",",
                "."
            )
        },
        {
            Name: "IS_APOLICE_COMPLEMENTAR_ANTERIOR",
            Value: formatStrMoney(
                apolice.ComplementaryInsuredAmountValue,
                2,
                ",",
                "."
            )
        },
        {
            Name: "IS_APOLICE_COMPLEMENTAR_NOVA",
            Value: formatStrMoney(
                endosso.ComplementaryInsuredAmountValue,
                2,
                ",",
                "."
            )
        },
        {
            Name: "TAG_MODAL_BASICA",
            Value: apolice.Modality.Name
        },
        {
            Name: "TAG_DATA_ENDOSSO_BASICA",
            Value: endosso.EndDate ? moment(endosso.EndDate).format("DD/MM/YYYY") : ObjectJSON.end
        },
        {
            Name: "TAG_DATA_ENDOSSO_SECUNDARIA",
            Value: moment(endosso.ComplementaryEndDate).format("DD/MM/YYYY")
        },
        {
            Name: "TAG_MODAL_BASICA",
            Value: apolice.Modality.Name
        },
        {
            Name: "DATA_ENDOSSO_BASICA",
            Value: moment(endosso.EndDate).format("DD/MM/YYYY")
        },
        {
            Name: "DATA_ENDOSSO_SECUNDARIA",
            Value: moment(endosso.ComplementaryEndDate).format("DD/MM/YYYY")
        },
        {
            Name: "NR_APOLICE",
            Value: apolice.PolicyNumber
        },
        {
            Name: "TAG_OBJETO",
            Value: ((endosso.ContractualTerms || {}).Object || []).map(x => {
                return `${x.Value} <br />`;
            })
        },
        {
            Name: "CLAUSAS_PARTICULARES",
            Value: ((endosso.ContractualTerms || {}).ParticularClausule || []).map(x => {
                return `${x.Value} <br />`;
            })
        },
        ];
    }

    replaceAndFormatTags() { }
}

export default new TagHelpers();