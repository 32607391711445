<template>
  <li :class="{ active: tab.active }">
    <span class="line"></span>
    <a href="javascript:void(0)" :class="{ disabled: !isChecked }">
      <div
        class="wizard-icon-circle md"
        role="tab"
        :tabindex="isChecked ? 0 : ''"
        :id="`step-${tab.tabId}`"
        :aria-controls="tab.tabId"
        :aria-disabled="tab.active"
        :aria-selected="tab.active"
        :class="{
          checked: isChecked,
          square_shape: isStepSquare,
          tab_shape: isTabShape
        }"
        :style="[
          isChecked ? stepCheckedStyle : {},
          tab.validationError ? errorStyle : {}
        ]"
      >
        <transition :name="transition" mode="out-in">
          <div
            v-if="tab.active"
            class="wizard-icon-container"
            :class="{ square_shape: isStepSquare, tab_shape: isTabShape }"
            :style="[
              tab.active ? iconActiveStyle : {},
              tab.validationError ? errorStyle : {}
            ]"
          >
            <slot name="active-step">
              <i
                v-if="iconChecked && !showNumber"
                :class="iconChecked"
                class="wizard-icon"
              ></i>
              <i v-else class="wizard-icon">{{ index + 1 }}</i>
            </slot>
          </div>
          <slot v-else>
            <i
              v-if="!tab.active && iconChecked && !showNumber"
              :class="iconChecked"
              class="wizard-icon"
            ></i>
            <i
              v-if="(!tab.active && !iconChecked) || showNumber"
              class="wizard-icon"
              >{{ index + 1 }}</i
            >
          </slot>
        </transition>
      </div>
      <slot name="title">
        <span
          class="stepTitle"
          :class="{ active: tab.active, has_error: tab.validationError }"
          :style="tab.active ? stepTitleStyle : {}"
        >
          {{ tab.title }}
        </span>
      </slot>
    </a>
  </li>
</template>
<script>
export default {
  name: "wizard-step",
  props: {
    tab: {
      type: Object,
      default: () => {}
    },
    transition: {
      type: String,
      default: ""
    },
    index: {
      type: Number,
      default: 0
    },
    checked: {
      type: Boolean,
      default: undefined
    },
    disabled: {
      type: Boolean,
      default: undefined
    },
    showNumber: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      iconChecked: ""
    };
  },
  computed: {
    iconActiveStyle() {
      return {
        backgroundColor: "#28C76F"
      };
    },
    isChecked: {
      get() {
        return this.checked || this.tab.checked;
      }
    },
    stepCheckedStyle() {
      this.iconChecked = "ti-check";
      let style = {
        backgroundColor: "#28C76F",
        borderColor: "#28C76F",
        color: "white"
      };
      if (this.disabled)
        style = {
          ...style,
          backgroundColor: "#FFFFFF",
          borderColor: "#cccccc",
          color: "#cccccc"
        };
      return style;
    },
    errorStyle() {
      return {
        borderColor: this.tab.errorColor,
        backgroundColor: this.tab.errorColor
      };
    },
    stepTitleStyle() {
      const isError = this.tab.validationError;
      return {
        color: isError ? this.tab.errorColor : "#28C76F"
      };
    },
    isStepSquare() {
      return this.tab.shape === "square";
    },
    isTabShape() {
      return this.tab.shape === "tab";
    }
  }
};
</script>
<style lang="scss">
.wizard-progress-bar {
  background-color: rgba(var(--vs-success), 1) !important;
}

.disabled-step {
  opacity: 0.5;
}

.vue-form-wizard .wizard-icon-circle .wizard-icon-container {
  border-radius: 50% !important;
}
</style>
