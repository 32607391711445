/*=========================================================================================
  File Name: globalComponents.js
  Description: Here you can register components globally
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from "vue";
import { FormWizard, TabContent, WizardButton } from "vue-form-wizard";
// v-select component
import vSelect from "vue-select";
import AzUploadComponent from "./components/az-upload/AzUpload.vue";
import AppDialog from "./components/dialogs/AppDialog.vue";
import FeatherIcon from "./components/FeatherIcon.vue";
import OnpointInput from "./components/onpointInput/index.vue";
import VxCard from "./components/vx-card/VxCard.vue";
import VxInputGroup from "./components/vx-input-group/VxInputGroup.vue";
import VxList from "./components/vx-list/VxList.vue";
import WizardStep from "./components/wizard-step/WizardStep.vue";
import "./directives/storage";
import PopupBase from "./layouts/components/popup-base/PopupBase.vue";
import VxTooltip from "./layouts/components/vx-tooltip/VxTooltip.vue";
import VxBreadcrumb from "./layouts/components/VxBreadcrumb.vue";

Vue.component(VxTooltip.name, VxTooltip);
Vue.component(VxCard.name, VxCard);
Vue.component(VxList.name, VxList);
Vue.component(VxBreadcrumb.name, VxBreadcrumb);
Vue.component(OnpointInput.name, OnpointInput);
Vue.component(VxInputGroup.name, VxInputGroup);
Vue.component(FeatherIcon.name, FeatherIcon);
Vue.component(AzUploadComponent.name, AzUploadComponent);
Vue.component(OnpointInput.name, OnpointInput);
Vue.component("app-dialog", AppDialog);
Vue.component("popup-base", PopupBase);


// Set the components prop default to return our fresh components
vSelect.props.components.default = () => ({
    Deselect: {
        render: createElement => createElement("feather-icon", {
            props: {
                icon: "XIcon",
                svgClasses: "w-4 h-4 mt-1"
            }
        })
    },
    OpenIndicator: {
        render: createElement => createElement("feather-icon", {
            props: {
                icon: "ChevronDownIcon",
                svgClasses: "w-5 h-5"
            }
        })
    }
});

Vue.component("v-select", vSelect);

Vue.component("form-wizard", FormWizard);
Vue.component("tab-content", TabContent);
Vue.component("wizard-button", WizardButton);


Vue.component("wizard-step", WizardStep);
Vue.component("form-wizard", Vue.options.components["form-wizard"].extend({
    components: {
        WizardButton,
        WizardStep
    }
}));
