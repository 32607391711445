import instance from "../../axios";

export default {
  namespaced: true,
  state: {
    corretora: {}
  },
  mutations: {
    updateCorretora(state, corretora) {
      state.corretora = corretora;
    }
  },
  actions: {
    getCorretora({ commit }) {
      return instance
        .get("/api/Broker/GetCurrentBroker")
        .then(response => response.data.Response)
        .then(response => commit("updateCorretora", response) || response);
    },

    buscarInformacoesNovaCorretora({ commit }, cnpj) {
      return instance
        .get(`/api/Broker/ValidateBrokerRegistrationRequest?Cnpj=${cnpj}`)
        .then(response => response.data.Response)
        .then(response => {
          response.BankId = "";
          response.BankAccountTypeId = "";
          commit("updateCorretora", response) || response;
        });
    },

    cadastrarCorretora({ commit }, corretora) {
      return instance({
          method: "post",
          url: "/api/Broker/InsertBroker",
          data: corretora
        })
        .then(response => {
          return response.data.Response;
        })
        .then(data => commit("updateCorretora", data) || data);
    },

    atualizarCorretora({ commit }, corretora) {
      return instance({
          method: "put",
          url: "/api/Broker/UpdateBroker",
          data: corretora
        })
        .then(response => {
          return response.data.Response;
        })
        .then(data => commit("updateCorretora", data) || data);
    },

    async validarCorretoraAtiva({ commit }) {
      return await instance
        .get("api/Broker/ValidateBrokerStatus")
        .then(response => {
          return response.data.Response;
        });
    }
  },
  getters: {
    corretora(state) {
      return state.corretora;
    },
    bDocumentacaoPendente(state) {
      return (state.corretora || {}).HasPendingDocuments;
    },
    bValidacaoCompliancePendente(state) {
      return (state.corretora || {}).HasPendingComplianceValidation;
    },
    bAguardandoAnalise(state) {
      return (state.corretora || {}).WaitingValidation;
    }
  }
};