var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: _vm.vueAppClasses, attrs: { id: "app" } },
    [
      _vm.perfilHelpers.isInsurance() ? _c("helpDesk") : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: { id: "aviso", title: "Aviso!", visible: _vm.warnMaintenance },
          on: {
            "update:visible": function ($event) {
              _vm.warnMaintenance = $event
            },
          },
        },
        [_c("p", { domProps: { innerHTML: _vm._s(_vm.maintenanceMessage) } })]
      ),
      _c("router-view", { on: { setAppClasses: _vm.setAppClasses } }),
      _c(
        "vs-popup",
        {
          staticClass: "termos-modal",
          attrs: {
            fullscreen: "",
            "button-close-hidden": true,
            title: "Atualização de Termos de Uso",
            active: _vm.assinarNovoTermoDeUso,
          },
          on: {
            "update:active": function ($event) {
              _vm.assinarNovoTermoDeUso = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "flex flex-col items-center pt-4" },
            [
              _c("span", {
                domProps: {
                  innerHTML: _vm._s(_vm.newTermsOfUse.HTMLTermOfUse),
                },
              }),
              _c(
                "vs-button",
                {
                  staticClass: "self-end mt-2 float-right",
                  attrs: { size: "large", color: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.assignNewTermOfUse()
                    },
                  },
                },
                [_vm._v("\n        Li e concordo\n      ")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "vs-popup",
        {
          attrs: {
            title: "",
            active: _vm.refreshTokenModal,
            buttonCloseHidden: true,
          },
          on: {
            "update:active": function ($event) {
              _vm.refreshTokenModal = $event
            },
          },
        },
        [
          _c("div", { staticClass: "vx-row px-8" }, [
            _c("div", { staticClass: "vx-col w-3/12" }, [
              _c("div", {
                staticClass: "vs-con-loading__container",
                attrs: { id: "refresh-token-loading" },
              }),
            ]),
            _c("div", { staticClass: "vx-col w-9/12" }, [
              _c("h4", [_vm._v("Seu token de acesso expirou")]),
              _c("p", { staticClass: "mt-4" }, [
                _vm._v(
                  "\n          Aguarde. Ele está sendo atualizado. Ao fim sua página será\n          atualizada\n        "
                ),
              ]),
            ]),
          ]),
        ]
      ),
      _c(
        "vs-popup",
        {
          staticClass: "disclamer",
          attrs: { title: "", active: _vm.disclamer, buttonCloseHidden: false },
          on: {
            "update:active": function ($event) {
              _vm.disclamer = $event
            },
          },
        },
        [
          _c("div", { staticClass: "vx-row px-8" }, [
            _c("div", { staticClass: "vx-col w-12/12" }, [
              _c("h2", { staticStyle: { color: "red" } }, [
                _vm._v("ATENÇÃO!!"),
              ]),
              _c("br"),
              _c("p", { staticClass: "mt-4" }, [
                _vm._v("\n          As "),
                _c("b", [_vm._v(" 18:00 horas do dia 30/12/2022")]),
                _vm._v(" esta\n          "),
                _c("b", [_vm._v("plataforma entrará em manutenção")]),
                _vm._v(
                  " para a implantação dos novos\n          produtos/modalidades referente a Circular 662/22.\n        "
                ),
              ]),
              _c("p", { staticClass: "mt-4" }, [
                _vm._v(
                  "\n          Informamos que todas as propostas que não foram emitidas até as\n          18:00 horas do dia 30/12/2022, mesmo que aprovadas, serão\n          automaticamente canceladas (propostas com status Aguardando Emissão,\n          Aguardando Corretor, Em análise).\n        "
                ),
              ]),
              _c("p", { staticClass: "mt-4" }, [
                _vm._v("Agradecemos a compreensão!"),
              ]),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }