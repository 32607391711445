import { kanbanProvider } from "../../providers/kanban.provider";

export default {
  namespaced: true,
  state: {
    pipes: [],
    pipe: {},
    steps: [],
    step: [],
    cards: [],
    showCardDataView: false
  },
  mutations: {
    updatePipes(state, data) {
      state.pipes = data;
    },
    setPipe(state, data) {
      state.pipe = data;
    },
    updateSteps(state, data) {
      state.steps = data;
    },
    updateCards(state, data) {
      state.cards = data;
    },
    updateShowCardDataView(state, data) {
      state.showCardDataView = data;
    },

    updateStep(state, data) {
      state.steps.forEach(step => {
        if (data.stepId == step.id) {
          step.cards = step.cards.concat(data.cards);
        }
      });
    }
  },
  actions: {
    async getPipes({ commit }) {
      return await kanbanProvider
        .getPipes()
        .then(data => commit("updatePipes", data) || data);
    },
    async getPipe({ commit }, id) {
      return await kanbanProvider
        .getPipe(id)
        .then(data => commit("setPipe", data) || data);
    },
    async getPipeSteps({ commit, getters }, payload) {
      if (getters.pipe && getters.pipe.id !== payload.pipeId) {
        commit("updateSteps", []); //para que não apareçam os steps do pipe anterior ao carregar um novo
      }

      return await kanbanProvider
        .getPipeSteps(payload.pipeId, payload.filterCardsValues)
        .then(data => {
          if (data) {
            data.forEach(step => {
              if (step.cards) {
                step.cards.forEach(card => {
                  if (!card.cardData) {
                    card.cardData = {
                      header: "Não Informado",
                      body: {
                        p1: "Informações do card não informadas."
                      },
                      footer: {
                        hideFooterDetailedButton: true
                      }
                    };
                  }
                });
              }
            });
          }
          commit("updateSteps", data);
        });
    },

    async getStepCards({ commit, getters }, payload) {
      return await kanbanProvider
        .getStepCards(
          payload.pipeId,
          payload.stepId,
          payload.skip,
          payload.filterCardsValues
        )
        .then(data => {
          commit("updateStep", { stepId: payload.stepId, cards: data });
        });
    },

    async filterAllCards({ commit, getters }, id) {
      if (getters.pipe && getters.pipe.id !== id) {
        commit("updateSteps", []); //para que não apareçam os steps do pipe anterior ao carregar um novo
      }
      return await kanbanProvider.filterAllCards(id).then(data => {
        if (data) {
          data.forEach(step => {
            if (step.cards) {
              step.cards.forEach(card => {
                if (!card.cardData) {
                  card.cardData = {
                    header: "Não Informado",

                    body: {
                      p1: "Informações do card não informadas."
                    },

                    footer: {
                      hideFooterDetailedButton: true
                    }
                  };
                }
              });
            }
          });
        }
        commit("updateSteps", data);
      });
    },

    async getPipeStepsByPriority({ commit, getters }, payload) {
      if (getters.pipe && getters.pipe.id !== payload.pipeId) {
        commit("updateSteps", []); //para que não apareçam os steps do pipe anterior ao carregar um novo
      }

      return await kanbanProvider
        .getPipeStepsByPriority(payload.pipeId, payload.ids)
        .then(data => {
          if (data) {
            data.forEach(step => {
              if (step.cards) {
                step.cards.forEach(card => {
                  if (!card.cardData) {
                    card.cardData = {
                      header: "Não Informado",
                      body: {
                        p1: "Informações do card não informadas."
                      },
                      footer: {
                        hideFooterDetailedButton: true
                      }
                    };
                  }
                });
              }
            });
          }
          commit("updateSteps", data);
        });
    },

  },
  getters: {
    pipes(state) {
      return state.pipes;
    },
    pipe(state) {
      return state.pipe;
    },
    steps(state) {
      return state.steps;
    },

    showCardDataView(state) {
      return state.showCardDataView;
    }
  }
};
