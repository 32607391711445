import Vue from "vue";
Vue.filter("setColorStatus", function(StatusId, TimelineStatusId) {
  if (StatusId == 1) {
    return "dark";
  } else if (StatusId == 2) {
    return "#0F6EB4";
  } else if (StatusId == 3) {
    return TimelineStatusId == 3 ? "warning" : "#0F6EB4";
  } else if (StatusId == 4) {
    return "#0F6EB4";
  } else if (StatusId == 5) {
    return "#249C6A";
  } else if (StatusId == 6) {
    return "#9437FF";
  } else if (StatusId == 97) {
    return "danger";
  } else if (StatusId == 98) {
    return "danger";
  } else if (StatusId == 99) {
    return "danger";
  } else {
    return "dark";
  }
});
Vue.filter("setLabelStatus", function(value) {
  if (value == 1) {
    return "Cotação";
  } else if (value == 2) {
    return "Em análise";
  } else if (value == 3) {
    return "Aguardando Emissão";
  } else if (value == 4) {
    return "Aguardando Pagamento de Boleto";
  } else if (value == 5) {
    return "Emitida";
  } else if (value == 6) {
    return "Aguardando Corretor";
  } else if (value == 97) {
    return "Rejeitada";
  } else if (value == 98) {
    return "Recusada";
  } else if (value == 99) {
    return "Cancelada";
  } else {
    return value;
  }
});


Vue.filter("setLabelStatusPolicy", function(PolicyStatusId) {
  if (PolicyStatusId == 1) {
    return "#0F6EB4";
  } else if (PolicyStatusId == 2) {
    return "#249C6A";
  } else if (PolicyStatusId == 3) {
    return "danger";
  } else if (PolicyStatusId == 4) {
    return "danger";
  } else if (PolicyStatusId == 5) {
    return "danger";
  } 
   else {
    return "dark";
  }
});
