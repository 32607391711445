var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("li", { class: { active: _vm.tab.active } }, [
    _c("span", { staticClass: "line" }),
    _c(
      "a",
      {
        class: { disabled: !_vm.isChecked },
        attrs: { href: "javascript:void(0)" },
      },
      [
        _c(
          "div",
          {
            staticClass: "wizard-icon-circle md",
            class: {
              checked: _vm.isChecked,
              square_shape: _vm.isStepSquare,
              tab_shape: _vm.isTabShape,
            },
            style: [
              _vm.isChecked ? _vm.stepCheckedStyle : {},
              _vm.tab.validationError ? _vm.errorStyle : {},
            ],
            attrs: {
              role: "tab",
              tabindex: _vm.isChecked ? 0 : "",
              id: `step-${_vm.tab.tabId}`,
              "aria-controls": _vm.tab.tabId,
              "aria-disabled": _vm.tab.active,
              "aria-selected": _vm.tab.active,
            },
          },
          [
            _c(
              "transition",
              { attrs: { name: _vm.transition, mode: "out-in" } },
              [
                _vm.tab.active
                  ? _c(
                      "div",
                      {
                        staticClass: "wizard-icon-container",
                        class: {
                          square_shape: _vm.isStepSquare,
                          tab_shape: _vm.isTabShape,
                        },
                        style: [
                          _vm.tab.active ? _vm.iconActiveStyle : {},
                          _vm.tab.validationError ? _vm.errorStyle : {},
                        ],
                      },
                      [
                        _vm._t("active-step", function () {
                          return [
                            _vm.iconChecked && !_vm.showNumber
                              ? _c("i", {
                                  staticClass: "wizard-icon",
                                  class: _vm.iconChecked,
                                })
                              : _c("i", { staticClass: "wizard-icon" }, [
                                  _vm._v(_vm._s(_vm.index + 1)),
                                ]),
                          ]
                        }),
                      ],
                      2
                    )
                  : _vm._t("default", function () {
                      return [
                        !_vm.tab.active && _vm.iconChecked && !_vm.showNumber
                          ? _c("i", {
                              staticClass: "wizard-icon",
                              class: _vm.iconChecked,
                            })
                          : _vm._e(),
                        (!_vm.tab.active && !_vm.iconChecked) || _vm.showNumber
                          ? _c("i", { staticClass: "wizard-icon" }, [
                              _vm._v(_vm._s(_vm.index + 1)),
                            ])
                          : _vm._e(),
                      ]
                    }),
              ],
              2
            ),
          ],
          1
        ),
        _vm._t("title", function () {
          return [
            _c(
              "span",
              {
                staticClass: "stepTitle",
                class: {
                  active: _vm.tab.active,
                  has_error: _vm.tab.validationError,
                },
                style: _vm.tab.active ? _vm.stepTitleStyle : {},
              },
              [_vm._v("\n        " + _vm._s(_vm.tab.title) + "\n      ")]
            ),
          ]
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }