import instance from "../../axios";

export default {
  namespaced: true,
  state: {
    documentos: {}
  },
  mutations: {
    updateDocumentos (state, documentos) {
      state.documentos = documentos;
    }
  },
  actions: {
    async uploadDocumento ({commit}, data) {
      return await instance({
        method: "post",
        url: "/api/Broker/UploadBrokerRequestDocumentArchive",
        data
      })
        .then(response => response.data.Response);
    },

    async submeterDotumentoParaAnalise ({commit}, data) {
      return await instance({
        method: "post",
        url: "/api/Broker/SubmitDocumentToReview",
        data
      })
        .then(response => response.data.Response);       
    },

    async getUrlFileDownload ({ commit }, id) {
      return await instance
        .get(`/api/Application/GetUrlFileDownload?ArchiveUniqueId=${id}`)
        .then((data) => (data.data || {}).Response)
        .catch((data) => {
          if (data && data.response && data.response.data) return Promise.reject(data.response.data.Errors);
         
          return Promise.reject("Erro ao tentar executar a solicitação.");
        });
    },

    async getDocumentos ({ commit }) {
      return await instance
        .get("/api/Broker/GetBrokerRequestDocuments")
        .then(response => response.data.Response)
        .then(response => commit("updateDocumentos", response) || response);
    },

    async obterDocumentosPorCorretora ({ commit }, id) {
      return await instance
        .get(`/api/Broker/GetBrokerRequestDocumentsByBroker?brokerId=${id}`)
        .then(response => response.data.Response);
    },

    async obterDocumentosRequestLimit ({ commit }, id) {
      return await instance
        .get(`/api/policyHolder/GetRequestLimitDocuments?policyHolderUniqueId=${id}`)
        .then(response => response.data.Response);
    },

    async obterDocumentosDeletadosPorCorretora ({ commit }, id) {
      return await instance
        .get(`/api/Broker/GetBrokerRequestDocumentsDeletedByBroker?brokerId=${id}`)
        .then(response => response.data.Response)
        .then(response => commit("updateDocumentos", response) || response);
    },

    async getDocumentoPorId ({ commit }, id) {
      return await instance
        .get(`/api/Broker/GetBrokerRequestDocumentArchives?BrokerRequestDocumentId=${id}`)
        .then(response => response.data.Response);
    },

    async createBrokerRequestDocumentByInsurer  ({commit}, data) {
      return await instance({
        method: "post",
        url: "/api/Broker/CreateBrokerRequestDocumentByInsurer",
        data
      })
        .then(response => response.data.Response);
    },
    async uploadBrokerRequestDocumentArchiveByInsurer  ({commit}, data) {
      return await instance({
        method: "post",
        url: "/api/Broker/UploadBrokerRequestDocumentArchiveByInsurer",
        data
      })
        .then(response => response.data.Response);
    },
    async deleteBrokerRequestDocument  ({commit}, data) {
      return await instance({
        method: "post",
        url: "/api/Broker/DeleteBrokerRequestDocument",
        data
      })
        .then(response => response.data.Response);
    },
  },
  getters: {
    documentos (state) {
      return state.documentos;
    }
  }
};
