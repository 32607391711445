// Functions

import onPointErrorModal from "./onpointErrorModal/index.js";
import onPointSuccessModal from "./onpointSuccessModal/index.js";
import onPointLoadingModal from "./onpointLoadingModal/index.js";
import onPointLoading from "./onpointLoading/index.js";
import onPointNotification from "./onpointNotification/index.js";
import onPointLoadingMultipleRequests from "./onpointLoadingMultipleRequests/index.js";

const onPointfunctions = {
  onPointErrorModal,
  onPointLoadingModal,
  onPointSuccessModal,
  onPointLoading,
  onPointNotification,
  onPointLoadingMultipleRequests
};

export default Vue => {
  Vue.prototype.$onpoint = {};
  Object.values(onPointfunctions).forEach(onPointfunction => {
    if (onPointfunction.hasOwnProperty("subName")) {
      Vue.prototype.$onpoint[onPointfunction.name][onPointfunction.subName] = onPointfunction.onPointFunction;
    } else {
      Vue.prototype.$onpoint[onPointfunction.name] = onPointfunction.onPointFunction;
    }
  });
};
