import Vue from "vue";

export default {
  name:"notification",
  onPointFunction (param) {
    const anonymoutComponent = Vue.extend({});
    const instance = new anonymoutComponent();
    let notifyProps = {
      title:param.title || "Sucesso",
      text:param.message || "Operação executada com sucesso",
      color:"success",
      position:"top-right"
    };
    if (param.type == "success") notifyProps = {...notifyProps, color:"success"};

    instance.$vs.notify(notifyProps);
    
  }
};
//$vs.notify({title:'Position Default',text:'Lorem ipsum dolor sit amet, consectetur',color:'primary'})