import instance from "../../axios";

export default {
  namespaced: true,
  state: {
    segurado: {},
    searchSegurados: []
  },
  mutations: {
    updateSegurado(state, segurado) {
      state.segurado = segurado;
    },
    updateSeguradoLocation(state, location) {
      state.segurado = {...state.segurado, Locations: location };
    },
    updateSearchSegurados(state, data) {
      state.searchSegurados = data;
    },
  },
  actions: {
    async searchSegurados({ commit }, params) {
      const url = `InsuredName=${params.filter.insuredName}&InsuredDocument=${params.filter.insuredDocument}&currentPage=${params.currentPage}&rowsPerPage=${params.rowsPerPage}`;
      return await instance
        .get(
          `/api/Insured/SearchInsureds?${url}`
        )
        .then(response => response.data.Response)
        .then(
          response => commit("updateSearchSegurados", response) || response
        );
    },

    async getHistory({}, id) {
      return await instance.get(`/api/InsuredChangeHistory/Get?insuredId=${id}`)
        .then(response => response.data.Response.map(item => {
          return {...item, ChangedByName: (item.ChangesHistories || item.LocationChangesHistories || [])[0].ChangedByName }
        }))
    },

    async carregarSegurado({ commit }, insuredUniqueId) {
      return await instance
        .get(
          `/api/Insured/GetInsured?personUniqueId=${insuredUniqueId}`
        )
        .then(response => response.data.Response)
        .then(data => commit("updateSegurado", data) || data);
    },
    async blockUnblockInsured({}, payload) {
      return await instance
        .patch(`/api/Insured/SetBlocked?id=${payload.id}&isBlocked=${payload.isBlocked}&blockJustify=${payload.justify}`)
        .then(response => response.data.Response)
    },

    async updateInsuredName({}, payload) {
      return await instance
        .patch(`/api/Insured/UpdateInsuredName?id=${payload.id}&insuredName=${payload.insuredName}`)
        .then(response => response.data.Response)
    },

    async updateInsuredBranch({}, payload) {
      return await instance
        .patch(`/api/Insured/UpdateInsuredBranch?id=${payload.id}&isPrivate=${payload.branch}`)
        .then(response => response.data.Response)
    },

    async saveInsuredLocation({ commit }, payload) {
      return await instance
        .post(`/api/Insured/SaveInsuredLocation`, payload.data)
        .then(response => response.data.Response)
        .then(data => commit("updateSeguradoLocation", data) || data);
    },

    async updateInsuredLocation({}, payload) {
      return await instance
        .patch(`/api/Insured/UpdateLocation?id=${payload.data.Id}`, payload.data)
        .then(response => response.data.Response)
    },

    async removePersonLocation({}, payload) {
      return await instance
        .delete(`/api/Insured/RemoveInsuredLocation?locationId=${payload.locationId}`)
        .then(response => response.data.Response)
    },
    async getDocuments({ commit }, data) {
      return await instance
        .get(
          `/api/Insured/GetDocumentsByInsuredUniqueId?UniqueId=${data}`
        )
        .then(response => response.data.Response);
    },
    async uploadDocument({ commit }, data) {
      return await instance({
        method: "post",
        url: "api/Insured/UploadRequestDocument",
        data
      }).then(response => response.data.Response);
    },
    async deleteRequestDocument({ commit }, data) {
      return await instance({
        method: "post",
        url: "api/Insured/DeleteRequestDocument",
        data
      }).then(response => response.data.Response);
    },
    async createDocument({ commit }, data) {
      return await instance({
        method: "post",
        url: "api/Insured/CreateRequestDocument",
        data
      }).then(response => response.data.Response);
    },
  },
  getters: {
    segurado(state) {
      return state.segurado;
    },

    segurados(state) {
      return (state.searchSegurados || {}).Data || [];
    },

    searchSeguradosResult(state) {
      return state.searchSegurados;
    },
  }
};