import instance from "../../axios";

export default {
  namespaced: true,
  state: {
    propostas: {}
  },
  mutations: {
    updatePropostas(state, data) {
      state.propostas = data;
    }
  },
  actions: {
    async getPropostas({ commit }, params) {
      const url = `Number=${params.number}&PolicyHolderName=${params.policyHolderName}&Status=${params.status}&PolicyHolderCpfCnpj=${params.policyHolderCpfCnpj}&InsuredName=${params.insuredName}&InsuredCpfCnpj=${params.insuredCpfCnpj}&StartDate=${params.startDate}&EndDate=${params.endDate}`;
      return await instance
        .get(`api/Proposal/GetProposals?${url}`)
        .then(response => response.data.Response)
        .then(data => commit("updatePropostas", data) || data);
    },

    async buscarPropostas({ commit }, params) {
      const url = `Number=${params.number}&PolicyHolderName=${params.policyHolderName}&StatusId=${params.statusId}&PolicyHolderCpfCnpj=${params.policyHolderCpfCnpj}&InsuredName=${params.insuredName}&InsuredCpfCnpj=${params.insuredCpfCnpj}&StartDate=${params.startDate}&EndDate=${params.endDate}&currentPage=${params.currentPage}&rowsPerPage=${params.rowsPerPage}`;
      return await instance
        .get(`api/Proposal/SearchProposals?${url}`)
        .then(response => response.data.Response)
        .then(data => commit("updatePropostas", data) || data);
    },

    async buscarPropostasCorretoraPorTexto({ commit }, data) {
      return await instance
        .post(`api/Proposal/SearchBrokerProposalByText?TextSearch=${data}`)
        .then(response => response.data.Response);
    },

    async getPropostaByUniqueId({ commit }, id) {
      return await instance
        .get(`api/Proposal/GetProposalByUniqueId?ProposalUniqueId=${id}`)
        .then(response => response.data.Response);
    },

    async propostaAnalisePEP({ commit }, id) {
      return await instance
        .post(
          `api/Proposal/ExecuteAutomaticPEPApprovalValidation?ProposalUniqueId=${id}`
        )
        .then(response => response.data.Response);
    },

    async propostaCredito({ commit }, id) {
      return await instance
        .post(
          `api/Proposal/ExecuteAutomaticCreditApprovalValidation?ProposalUniqueId=${id}`
        )
        .then(response => response.data.Response);
    },

    async propostaAnaliseCadastro({ commit }, id) {
      return await instance
        .post(
          `api/Proposal/ExecuteAutomaticRegistrationApprovalValidation?ProposalUniqueId=${id}`
        )
        .then(response => response.data.Response);
    },

    async propostaSubscricao({ commit }, id) {
      return await instance
        .post(
          `api/Proposal/ExecuteAutomaticSubscriptionApprovalValidation?ProposalUniqueId=${id}`
        )
        .then(response => response.data.Response);
    },

    async propostaResseguro({ commit }, id) {
      return await instance
        .post(
          `api/Proposal/ExecuteAutomaticReinsuranceApprovalValidation?ProposalUniqueId=${id}`
        )
        .then(response => response.data.Response);
    },

    async gerarMinuta({ commit }, propostaUniqueId) {
      return await instance
        .get(
          `api/Proposal/GenerateContractDraft?ProposalUniqueId=${propostaUniqueId}&IsSaved=false`
        )
        .then(response => response.data.Response.UrlToDownload);
    },

    async downloadBoleto({ commit }, propostaUniqueId) {
      return await instance
        .get(
          `api/Proposal/DownloadBankSlip?ProposalUniqueId=${propostaUniqueId}`
        )
        .then(response => response.data.Response.LinksToDownload);
    },

    async downloadLaudoEmissao({ commit }, propostaUniqueId) {
      return await instance
        .get(
          `api/Proposal/LoadIssuanceReport?proposalUniqueId=${propostaUniqueId}`
        )
        .then(response => response.data.Response.UrlToDownloadIssuanceReport);
    }
  },
  getters: {
    propostas(state) {
      return state.propostas;
    }
  }
};
