<template>
  <vs-popup
    class="p-0 dialog"
    :title="''"
    :button-close-hidden="buttonCloseHidden"
    :active.sync="value"
    :fullscreen="fullscreen"
    style="width: 100% !important"
    @close="
      $emit('input', false);
      $emit('cancel');
    "
  >
    <template v-if="!!title">
      <vs-row>
        <h4 class="vx-col md:w-11/12 w-full m-auto">{{ title }}</h4>

        <vs-button
          class="vx-col md:w-1/12 w-full m-auto"
          vs-justify="flex-end"
          size="large"
          circle
          icon="close"
          @click="$emit('cancel')"
        ></vs-button>
      </vs-row>

      <vs-divider class="mb-0"></vs-divider>
    </template>

    <div class="pa-5">
      <div class="p-5">
        <slot name="content"></slot>
      </div>
      <vs-row vs-justify="flex-end">
        <slot name="actions">
          <vs-button
            class="mr-2"
            color="primary"
            type="flat"
            @click="$emit('cancel')"
          >
            <span class="font-semibold"> Cancelar </span>
          </vs-button>
          <vs-button color="primary" @click="$emit('confirm')">
            <span class="font-semibold"> Confirmar </span>
          </vs-button>
        </slot>
      </vs-row>
    </div>
  </vs-popup>
</template>

<script>
export default {
  props: {
    value: Boolean,
    title: String,
    fullscreen: Boolean,
    buttonCloseHidden: {
      type: Boolean,
      default: true
    }
  },
  watch: {},
  data() {
    return {
      sidebar: true
    };
  },
  mounted() {}
};
</script>
