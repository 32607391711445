<template>
  <div id="app" :class="vueAppClasses">
    <helpDesk  v-if="perfilHelpers.isInsurance()" />
    <el-dialog id="aviso" title="Aviso!" :visible.sync="warnMaintenance">
      <p v-html="maintenanceMessage"></p>
    </el-dialog>

    <router-view @setAppClasses="setAppClasses" />
    <vs-popup
      class="termos-modal"
      fullscreen
      :button-close-hidden="true"
      title="Atualização de Termos de Uso"
      :active.sync="assinarNovoTermoDeUso"
    >
      <div class="flex flex-col items-center pt-4">
        <span v-html="newTermsOfUse.HTMLTermOfUse"></span>

        <vs-button
          @click="assignNewTermOfUse()"
          class="self-end mt-2 float-right"
          size="large"
          color="primary"
        >
          Li e concordo
        </vs-button>
      </div>
    </vs-popup>
    <vs-popup
      title=""
      :active.sync="refreshTokenModal"
      :buttonCloseHidden="true"
    >
      <div class="vx-row px-8">
        <div class="vx-col w-3/12">
          <div
            id="refresh-token-loading"
            class="vs-con-loading__container"
          ></div>
        </div>
        <div class="vx-col w-9/12">
          <h4>Seu token de acesso expirou</h4>
          <p class="mt-4">
            Aguarde. Ele está sendo atualizado. Ao fim sua página será
            atualizada
          </p>
        </div>
      </div>
    </vs-popup>
    <vs-popup
      title=""
      :active.sync="disclamer"
      :buttonCloseHidden="false"
      class="disclamer"
    >
      <div class="vx-row px-8">
        <div class="vx-col w-12/12">
          <h2 style="color: red">ATENÇÃO!!</h2>
          <br />
          <p class="mt-4">
            As <b> 18:00 horas do dia 30/12/2022</b> esta
            <b>plataforma entrará em manutenção</b> para a implantação dos novos
            produtos/modalidades referente a Circular 662/22.
          </p>
          <p class="mt-4">
            Informamos que todas as propostas que não foram emitidas até as
            18:00 horas do dia 30/12/2022, mesmo que aprovadas, serão
            automaticamente canceladas (propostas com status Aguardando Emissão,
            Aguardando Corretor, Em análise).
          </p>
          <p class="mt-4">Agradecemos a compreensão!</p>
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>

import helpDesk from "@/components/help-desk/index.vue";
import * as authUtils from "./utils/auth";
import themeConfig from "@/../themeConfig.js";
import instance from "./axios";
import { mapGetters, mapActions, mapMutations } from "vuex";
import auth from "./store/modules/auth";
import environment from "./environment";
import perfilHelpers from "@/helpers/PerfilHelpers";
import jwt_decode from "jwt-decode";
import axios from "axios";
export default {
  components: {
    helpDesk,
  },
  data() {
    return {
      perfilHelpers: perfilHelpers,
      assinarNovoTermoDeUso: false,
      refreshTokenModal: false,
      newTermsOfUse: {},
      vueAppClasses: [],
      companyTermOfUseTags: [],
      warnMaintenance: true,
      maintenanceMessage: false,
      disclamer: false
    };
  },
  watch: {
    "newTermsOfUse.HTMLTermOfUse"(value) {
      if (!value) return;
      this.formatTagsTermsOfUse();
    },
    "$store.state.theme"(val) {
      this.toggleClassInBody(val);
    },
    "$vs.rtl"(val) {
      document.documentElement.setAttribute("dir", val ? "rtl" : "ltr");
    },
    needRefreshToken: {
      immediate: true,
      handler(val, oldVal) {
        if (val == true) this.callRefreshToken();
      }
    },
    isAuthenticated(value) {
      if (value) {
        this.verifyAcceptedTermsOfUse();
        this.getCompanyTermOfUseTags();
      }
    },
  },
  methods: {
    ...mapActions("auth", ["loadAuthData", "refreshToken", "getAcceptedTermsOfUse"]),
    toggleClassInBody(className) {
      if (className === "dark") {
        if (document.body.className.match("theme-semi-dark"))
          document.body.classList.remove("theme-semi-dark");
        document.body.classList.add("theme-dark");
      } else if (className === "semi-dark") {
        if (document.body.className.match("theme-dark"))
          document.body.classList.remove("theme-dark");
        document.body.classList.add("theme-semi-dark");
      } else {
        if (document.body.className.match("theme-dark"))
          document.body.classList.remove("theme-dark");
        if (document.body.className.match("theme-semi-dark"))
          document.body.classList.remove("theme-semi-dark");
      }
    },
    setAppClasses(classesStr) {
      this.vueAppClasses.push(classesStr);
    },
    handleWindowResize() {
      this.$store.commit("UPDATE_WINDOW_WIDTH", window.innerWidth);

      // Set --vh property
      document.documentElement.style.setProperty(
        "--vh",
        `${window.innerHeight * 0.01}px`
      );
    },
    handleScroll() {
      this.$store.commit("UPDATE_WINDOW_SCROLL_Y", window.scrollY);
    },
    assignNewTermOfUse() {
      this.$vs.loading();
      instance({
        method: "post",
        url: "/api/Security/AssignLastCompanyTermOfUse"
      })
        .then(() => {
          this.$vs.loading.close();
          this.assinarNovoTermoDeUso = false;
        })
        .catch(error => {
          this.$vs.loading.close();
          this.assinarNovoTermoDeUso = false;
        });
    },
    getCompanyTermOfUseTags() {
      return instance.get("api/Core/GetCompanyTermOfUseTags").then(response => {
        this.companyTermOfUseTags = response.data.Response;
      });
    },
    formatTagsTermsOfUse() {
      this.companyTermOfUseTags.forEach(tag => {
        const newTermsOfUseReplaced = this.newTermsOfUse.HTMLTermOfUse.replaceAll(
          `[${tag.Name}]`,
          tag.Value
        );
        this.newTermsOfUse.HTMLTermOfUse = newTermsOfUseReplaced;
      });
    },

    initRefreshTokenLoading() {
      this.$vs.loading({
        container: "#refresh-token-loading",
        scale: 2,
        color: "warning"
      });
    },
    callRefreshToken() {
      this.refreshTokenModal = true;
      this.refreshToken().then(
        data => {
          this.$vs.notify({
            title: "Sucesso",
            text: "Sua página será atualizada",
            color: "success"
          });
          setTimeout(() => {
            this.refreshTokenModal = false;
            window.location.reload();
          }, 750);
        },
        error => {
          this.$vs.notify({
            title: "Erro",
            text:
              "Não foi possível autenticar. Você será redirecionado à tela de login",
            color: "danger"
          });
          setTimeout(() => {
            this.refreshTokenModal = false;
            authUtils.logout();
          }, 750);
        }
      );
    },

    addStylesheetURL(url) {
      var link = document.createElement("link");
      link.rel = "stylesheet";
      link.href = url;
      document.getElementsByTagName("head")[0].appendChild(link);
    },

    verifyAcceptedTermsOfUse() {
      return this.getAcceptedTermsOfUse().then(data => {
        if (data.Response === false) {
          return instance({
            method: "get",
            url: "/api/Core/GetTermOfUse"
          }).then(termsData => {
            this.newTermsOfUse = termsData.data.Response;
            this.assinarNovoTermoDeUso = true;
          });
        }
      });
    }
  },
  computed: {
    ...mapGetters("auth", ["isAuthenticated"]),
    needRefreshToken: {
      get() {
        return this.$store.state.needRefreshToken;
      }
    }
  },
  created() {
    const dir = this.$vs.rtl ? "rtl" : "ltr";
    document.documentElement.setAttribute("dir", dir);
    window.addEventListener("resize", this.handleWindowResize);
    window.addEventListener("scroll", this.handleScroll);

    var portalConfig = JSON.parse(localStorage.getItem("lsApplicationConfig"));

    var isDev = JSON.parse(localStorage.getItem("isDev"));

    this.maintenanceMessage = portalConfig.maintenanceMessage;

    this.warnMaintenance =
      portalConfig.maintenance == true ? false : portalConfig.warnMaintenance;

    if ((portalConfig.maintenance == true) & !isDev) {
      this.$router.push({
        name: "page-error-503"
      });
    }
  },
  mounted() {
    this.initRefreshTokenLoading();

    if (this.isAuthenticated) {
      this.verifyAcceptedTermsOfUse();
      this.getCompanyTermOfUseTags();
    }

    this.toggleClassInBody(themeConfig.theme);
    this.$store.commit("UPDATE_WINDOW_WIDTH", window.innerWidth);

    const vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty("--vh", `${vh}px`);

    const applicationConfig = environment.getApplicationConfig();
    const primaryColor =
      (((applicationConfig || {}).layout || {}).common || {}).primaryColor ||
      "#0e65a5";
    const figureColor =
      (((applicationConfig || {}).layout || {}).common || {}).figureColor ||
      "#0e65a5";
    const lineColor =
      (((applicationConfig || {}).layout || {}).common || {}).lineColor ||
      "#ff9f43";
    const fontFamily =
      (((applicationConfig || {}).layout || {}).common || {}).FontFamily ||
      "Montserrat, Helvetica, Arial, sans-serif !default";
    const fontFamilyUrl =
      (((applicationConfig || {}).layout || {}).common || {}).FontFamilyUrl ||
      "https://fonts.googleapis.com/css2?family=Montserrat";

    if (primaryColor) {
      this.$vs.theme({ primary: primaryColor });
    }
    if (figureColor) {
      this.$vs.theme({ figureColor: figureColor });
    }
    if (lineColor) {
      this.$vs.theme({ lineColor: lineColor });
    }

    if (fontFamilyUrl) this.addStylesheetURL(fontFamilyUrl);

    if (fontFamily) {
      this.$vs.theme({ "font-family": fontFamily });
    }
  },
  destroyed() {
    window.removeEventListener("resize", this.handleWindowResize);
    window.removeEventListener("scroll", this.handleScroll);
  }
};
</script>

<style lang="scss">
.disclamer .vs-popup {
  width: 800px !important;
}
#refresh-token-loading {
  width: 120px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
<style lang="scss">
::-webkit-scrollbar {
  width: 13px;
  height: 13px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background: rgba(var(--vs-primary), 0.5) !important;
  border: 0px none #ffffff;
  border-radius: 50px;
}
::-webkit-scrollbar-thumb:hover {
  background: rgba(var(--vs-primary), 0.7) !important;
}
::-webkit-scrollbar-thumb:active {
  background: rgba(var(--vs-primary), 0.7) !important;
}
::-webkit-scrollbar-track {
  background: #dfdfdf;
  border: 0px none #dfdfdf;
  border-radius: 40px;
}
::-webkit-scrollbar-track:hover {
  background: #dfdfdf;
}
::-webkit-scrollbar-track:active {
  background: #dfdfdf;
}
::-webkit-scrollbar-corner {
  background: transparent;
}

.aviso {
  position: fixed;
  z-index: 999999999999999999999999999999;
  width: max-content;
  height: max-content;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
}
</style>

<style>
#aviso .el-dialog__title {
  font-size: 28px !important;
  font-weight: bold;
}
#aviso .el-dialog__body {
  font-size: 19px !important;
  word-break: initial !important;
}
</style>
