import instance from "../../axios";

export default {
    namespaced: true,
    state: {
        perfis: [],
        menuFuncionalities: []
    },
    mutations: {
        setPerfis(state, data) {
            state.perfis = data;
        },

        removeProfile(state, data) {
            state.perfis = (state.perfis || []).filter(x => x.UniqueId != data);
        },

        addProfile(state, data) {
            state.perfis[state.perfis.length] = data;
        },

        updateProfile(state, data) {
            let perfil;
            state.perfis.forEach(element => {
                if (element.UniqueId == data.UniqueId) perfil = element;
            });

            if (perfil) state.perfis[state.perfis.indexOf(perfil)] = data
        },

        updateUserProfile(state, data) {
            let perfil;
            state.perfis.forEach(element => {
                if (element.UniqueId == data.UniqueId) perfil = element;
            });

            if (perfil) {
                let index = state.perfis.indexOf(perfil);
                perfil.Usuarios = data.Usuarios
                state.perfis[index] = perfil
            }
        },

        setMenuFuncionalities(state, data) {
            state.menuFuncionalities = data
        }
    },
    actions: {
        async getProfileByCompanyId({ commit }, payload = null) {
            return await instance
                .get(`/api/UserProfile/GetProfileByCompanyId?userInfoTypeId=${payload}`)
                .then(response => response.data.Response)
                .then(data => commit("setPerfis", data));
        },

        async getMenuFuncionalities({ commit }, payload = null) {
            return await instance.get(`/api/Menu/GetMenus?userInfoTypeId=${payload}`)
                .then(response => response.data.Response)
                .then(data => commit("setMenuFuncionalities", data));
        },

        async insertOrUpdateProfile({}, payload) {
            return await instance.post("/api/UserProfile/InsertOrUpdateProfile", payload)
                .then(response => response.data.Response);
        },

        async updateUserInProfile({}, payload) {
            return await  instance.post("/api/UserProfile/UpdateUserInfoProfile", payload)
                .then(response => response.data.Response);
        },

        async updateMenuFuncionalityInProfile({}, payload) {
            return await  instance.post("/api/Menu/UpdateMenuFuncionalityInProfile", payload)
                .then(response => response.data.Response);
        },

        async deleteProfile({ commit }, payload) {
            return await instance.delete(`/api/UserProfile/DeleteProfile?UniqueId=${payload}`)
                .then(response => response.data.Response)
                .then(() => commit("removeProfile", payload));
        }
    },
    getters: {
        perfilList(state) {
            return state.perfis;
        },

        menuFuncionalities(state) {
            return state.menuFuncionalities
        }
    }
};
