var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vs-popup",
    {
      class: ["popup-base", _vm.fullscreen ? "" : _vm.size],
      style: _vm.style,
      attrs: {
        title: _vm.title,
        active: _vm.popupBase,
        fullscreen: _vm.fullscreen,
        "button-close-hidden": _vm.buttonCloseHidden,
      },
      on: {
        "update:active": function ($event) {
          _vm.popupBase = $event
        },
      },
    },
    [
      _c("div", { staticClass: "content-popup-base" }, [_vm._t("content")], 2),
      _c("div", { staticClass: "footer-popup-base" }, [_vm._t("footer")], 2),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }